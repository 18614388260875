import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as X } from 'assets/svg/x.svg';
import ShowInfo from 'components/info/showInfo';
import { SvgWrapper } from 'components/svg';
import {
  useAddWorkflowStepCommentMutation,
  useDeleteWorkflowStepCommentMutation,
  useGetWorkflowStepCommentsQuery,
  useUpdateWorkflowStepCommentMutation
} from 'services/api/workflowApi';
import { WorkflowStepApiData } from 'services/types/workflow';
import AddComment from './addComment';
import WorkflowViewerCommentCard from './commentCard';
import CommentSummary from './commentSummary';

type WorkflowViewerCommentsProps = {
  openComments: boolean;
  setOpenComments: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: WorkflowStepApiData;
};

const WorkflowViewerCommentsLayout = ({
  openComments,
  setOpenComments,
  activeStep
}: WorkflowViewerCommentsProps) => {
  const [commentId, setCommentId] = useState('');
  const [parentCommentId, setParentCommentId] = useState('');
  const [newComment, setNewComment] = useState('');
  const [addComment, setAddComment] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { data: getCommentsData } = useGetWorkflowStepCommentsQuery({
    workflowStepId: `${activeStep.step_id}`
  });
  const [addWorkflowStepComment, { isLoading: isLoadingAddComment }] =
    useAddWorkflowStepCommentMutation();
  const [updateWorkflowStepComment, { isLoading: isLoadingEditComment }] =
    useUpdateWorkflowStepCommentMutation();
  const [deleteWorkflowStepComment] = useDeleteWorkflowStepCommentMutation();

  const handleDeleteComment = async () => {
    await deleteWorkflowStepComment({
      commentId,
      workflowStepId: `${activeStep.step_id}`
    })
      .unwrap()
      .then(() =>
        ShowInfo({
          message: 'Comment deleted.',
          subText: '',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        })
      )
      .catch(error => {
        ShowInfo({
          message: 'Comment not deleted. Please try again.',
          subText: `${Object.values(error.data)[0]}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  const handleEditComment = async () => {
    await updateWorkflowStepComment({
      commentId,
      workflowStepId: `${activeStep.step_id}`,
      data: {
        comment: newComment
      }
    })
      .unwrap()
      .then(() => {
        setNewComment('');
        setAddComment(false);
        setIsEdit(false);
      })
      .catch(error => {
        ShowInfo({
          message: 'Cannot edit comment. Please try again.',
          subText: `${Object.values(error.data)[0]}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  const handleAddComment = () => {
    if (newComment.trim() === '') {
      ShowInfo({
        message: 'Comment cannot be empty.',
        type: 'warning',
        componentType: 'toast',
        hideProgressBar: false
      });
      return;
    }

    addWorkflowStepComment({
      workflowStepId: `${activeStep.step_id}`,
      data: {
        comment: newComment,
        parent_comment_id: parentCommentId ? parentCommentId : undefined
      }
    })
      .unwrap()
      .then(() => {
        setNewComment('');
        setParentCommentId('');
        setAddComment(false);
      })
      .catch(error => {
        ShowInfo({
          message: 'Comment not added. Please try again.',
          subText: `${Object.values(error.data)[0]}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <>
      {openComments ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            border: '.0625rem solid #F5F6F7',
            borderRadius: '.75rem',
            height: 'inherit',
            background: '#ffffff',
            width: {
              md: '10rem',
              lg: '15rem',
              xl: '20rem'
            }
          }}
        >
          <Box
            padding="0.75rem 1rem"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              borderBottom: '.064rem solid #F5F6F7',
              background: '#ffffff',
              borderRadius: '.75rem .75rem 0rem 0rem'
            }}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              Review Product Receipt
            </Typography>

            <Box
              padding="0.5rem"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: ' #F5F6F7',
                borderRadius: '6.25rem',
                cursor: 'pointer'
              }}
              onClick={() => {
                setAddComment(false);
                setOpenComments(false);
                setParentCommentId('');
                setNewComment('');
                setIsEdit(false);
              }}
            >
              <SvgWrapper
                height="1rem"
                width="1rem"
                icon={X}
                styleOverrides={{ fill: '#475467' }}
              />
            </Box>
          </Box>

          <Stack
            direction="column"
            width="inherit"
            height="100%"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              overflowY: 'auto',
              pb: '1rem'
            }}
          >
            {getCommentsData &&
              getCommentsData.map((comment, index) => (
                <WorkflowViewerCommentCard
                  key={comment.comment_id}
                  comment={comment}
                  stepTitle={activeStep.step_title}
                  commentNo={index + 1}
                  setCommentId={setCommentId}
                  setNewComment={setNewComment}
                  setAddComment={setAddComment}
                  setIsEdit={setIsEdit}
                  addReply={() => {
                    setAddComment(true);
                    setParentCommentId(comment.comment_id);
                  }}
                  handleDeleteComment={handleDeleteComment}
                />
              ))}
          </Stack>

          <AddComment
            addComment={addComment}
            setAddComment={setAddComment}
            newComment={newComment}
            setNewComment={setNewComment}
            setCommentId={setCommentId}
            setParentCommentId={setParentCommentId}
            isLoading={isLoadingEditComment || isLoadingAddComment}
            handleAddComment={handleAddComment}
            handleEditComment={handleEditComment}
            editCheck={isEdit}
            setIsEdit={setIsEdit}
          />
        </Stack>
      ) : (
        <CommentSummary
          displayComments={() => setOpenComments(true)}
          stepId={activeStep.step_id}
          commentNo={getCommentsData?.length ?? 0}
        />
      )}
    </>
  );
};

export default WorkflowViewerCommentsLayout;
