import { api } from './baseApi';
import { VaultMetaData } from 'services/types/vault';
import { WithIdTransformed, WithPagination } from 'services/types/common';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { convertToQueryString } from 'services/urlSearchParams';

type GetBranchVaultQueryArgs = {
  distributorId: string;
  params: {
    branch: string;
  };
};

export const vaultApi = api.injectEndpoints({
  endpoints: builder => ({
    getVaultMetaData: builder.query<VaultMetaData, unknown>({
      query: () => ({
        url: '{supplyChainProfileId}/product_receipts/vault-metadata/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['productReceiptMetadata']
    }),
    getCentralVaultProductReceipts: builder.query<
      WithPagination<WithIdTransformed<MiniProductReceiptApiData>>,
      unknown
    >({
      query: ({ distributorId }) => ({
        url: `{supplyChainProfileId}/product_receipts/vault/central/${distributorId}/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['getCentralVaultProductReceipts'],
      keepUnusedDataFor: 3600
    }),
    getBranchVaultProductReceipts: builder.query<
      WithPagination<WithIdTransformed<MiniProductReceiptApiData>>,
      GetBranchVaultQueryArgs
    >({
      query: ({ params, distributorId }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/vault/branch/${distributorId}/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      keepUnusedDataFor: 3600
    })
  }),
  overrideExisting: false
});

export const {
  useGetVaultMetaDataQuery,
  useGetCentralVaultProductReceiptsQuery,
  useGetBranchVaultProductReceiptsQuery,
  usePrefetch
} = vaultApi;
