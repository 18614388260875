import React, { HTMLAttributes } from 'react';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  InputAdornment,
  TextField
} from '@mui/material';
import { ReactComponent as Search } from 'assets/svg/search.svg';
import { theme } from 'themes/theme';
import { TypographyTheme } from 'themes/typography';
import { shadowsTheme } from 'themes/shadows';

interface SearchProps {
  id: string;
  options: ReadonlyArray<unknown>;
  onChange: (value: string) => void;
  filterOptions?: (
    options: unknown[],
    state: FilterOptionsState<string>
  ) => unknown[];
  getOptionLabel?: (option: unknown) => string;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  size: 'sm' | 'lg';
  placeholderText: string;
  styleOverrides?: {
    autocomplete?: object;
    textField?: object;
  };
}

const SearchBar = ({
  id,
  onChange,
  renderOption,
  filterOptions,
  getOptionLabel,
  options,
  size,
  placeholderText,
  styleOverrides = {}
}: SearchProps) => {
  const getTextStyleForSize = (size: SearchProps['size']) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: size === 'sm' ? '2.25rem' : '2.75rem',
      padding: size === 'sm' ? '0.5rem' : '0.75rem',
      borderRadius: '0.5rem',
      border: `0.09375rem solid ${theme.palette.common.progressBackground}`,
      background: `${theme.palette.common.card}`,
      boxShadow: shadowsTheme.buttonShadow,
      color: `${theme.palette.common.labelColor}`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      },
      '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      },
      ...styleOverrides.textField
    };
  };

  const getAutoCompleteStyleForSize = (size: SearchProps['size']) => {
    if (size === 'sm') {
      return {
        height: '2.25rem',
        ...styleOverrides.autocomplete
      };
    } else {
      return {
        height: '2.75rem',
        ...styleOverrides.autocomplete
      };
    }
  };

  const getSVGSize = (size: SearchProps['size']) => {
    if (size === 'sm') {
      return '1rem';
    } else {
      return '1.25rem';
    }
  };

  return (
    <Autocomplete
      disablePortal
      freeSolo
      id={id}
      options={options} // add search suggestions feature
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions} // Filter options based on search input
      open={filterOptions ? undefined : false}
      onInputChange={(_e, value) => {
        const castedValue = value as string;
        onChange(castedValue);
      }}
      fullWidth
      sx={getAutoCompleteStyleForSize(size)}
      renderInput={params => (
        <TextField
          {...params}
          id={id}
          sx={getTextStyleForSize(size)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  width={getSVGSize(size)}
                  height={getSVGSize(size)}
                  color="#98A2B3"
                />
              </InputAdornment>
            ),
            placeholder: placeholderText,
            sx: {
              '& ::placeholder': {
                ...TypographyTheme.bodyMediumRegular,
                color: '#70788D'
              },
              ...TypographyTheme.bodyMediumMedium,
              color: '#667085'
            }
          }}
        />
      )}
      renderOption={renderOption} // controls how the search results are displayed
    />
  );
};

export default SearchBar;
