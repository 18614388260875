import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { SvgWrapper } from 'components/svg';
import FormSelect from 'components/form/formSelect';
import FormTextArea from 'components/form/formTextArea';
import { Button } from 'components/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportProblemValidator } from '../validators/reportProblemValidators';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import {
  useCreateProductReceiptReportMutation,
  useGetProductReceiptComplaintTypesQuery
} from 'services/api/productReceiptApi';
import ShowInfo from 'components/info/showInfo';

interface ReportProblemModalProps {
  open: boolean;
  onClose: () => void;
  productReceiptId: string;
  productReceiptMerchantId: string;
}

type FormValues = {
  problemCategory: string;
  problemDescription: string;
};

const ReportProblemModal: React.FC<ReportProblemModalProps> = ({
  open,
  onClose,
  productReceiptId,
  productReceiptMerchantId
}) => {
  const {
    formState,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    trigger,
    clearErrors
  } = useForm<FormValues>({
    mode: 'onBlur', // Validate on blur instead of immediately
    defaultValues: {
      problemCategory: '',
      problemDescription: ''
    },
    resolver: yupResolver(reportProblemValidator)
  });

  const { isValid, isSubmitting } = formState;
  const problemCategory = watch('problemCategory');
  const problemDescription = watch('problemDescription');

  const [createProductReceiptReportRequest, { isLoading }] =
    useCreateProductReceiptReportMutation();
  const { data: complaintDataList } = useGetProductReceiptComplaintTypesQuery(
    {}
  );

  const complaintOptions = useMemo(() => {
    return (
      (complaintDataList &&
        Object.values(complaintDataList).map(complaint => ({
          title: complaint.type,
          subtitle: complaint.description,
          value: complaint.type
        }))) ||
      []
    );
  }, [complaintDataList]);

  const selectedComplaint = complaintOptions.find(
    option => option.value === problemCategory
  );

  // Track if the user has interacted with problemCategory
  const [categorySelected, setCategorySelected] = useState(false);

  useEffect(() => {
    if (problemCategory) {
      setCategorySelected(true);
    }

    if (problemCategory && problemCategory !== 'others') {
      setValue('problemDescription', selectedComplaint?.subtitle || '', {
        shouldDirty: true
      });

      if (categorySelected) {
        trigger('problemDescription'); // Validate description only after a category is chosen
      }
    } else {
      setValue('problemDescription', '', {
        shouldDirty: true
      });

      if (categorySelected) {
        trigger('problemDescription');
      }
    }
  }, [problemCategory, setValue, selectedComplaint, trigger, categorySelected]);

  // Clear errors when user starts typing in the description field after selecting "others"
  useEffect(() => {
    if (problemCategory === 'others' && problemDescription) {
      clearErrors('problemDescription');
    }
  }, [problemDescription, problemCategory, clearErrors]);

  const onSubmit = async (data: FormValues) => {
    await createProductReceiptReportRequest({
      productReceiptId,
      data: {
        referenced_organization: productReceiptMerchantId,
        complaint_type: data.problemCategory,
        complaint_description:
          data.problemCategory === 'others'
            ? data.problemDescription
            : selectedComplaint?.subtitle || ''
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Problem Successfully Reported!',
          subText: 'Thank you for reporting this issue',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      })
      .catch(() => {
        ShowInfo({
          message: 'Report Failed',
          subText: 'Product Receipt Report has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  const handleExitDialog = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0.75rem'
        }
      }}
    >
      <Box sx={{ px: 2, width: '26.25rem' }}>
        <DialogContent
          sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
        >
          <>
            <Stack
              sx={{
                flexDirection: 'row',
                background: '#FFF',
                pb: '1rem',
                py: '1rem'
              }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="column" alignItems="flex-start">
                  <Typography variant="h7Bold" color="#475467">
                    Report a Problem
                  </Typography>
                  <Typography variant="bodyMediumRegular" color="#667085">
                    Select all that apply as details for this problem
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={handleExitDialog}
                >
                  <SvgWrapper
                    icon={CloseIcon}
                    width="1.25rem"
                    height="1.25rem"
                    styleOverrides={{ fill: '#475467', stroke: 'none' }}
                  />
                </Box>
              </Box>
            </Stack>

            <Divider
              sx={{
                color: '#F5F6F7',
                border: '.06rem solid',
                mt: '-.15rem',
                mb: '.5rem'
              }}
            />
          </>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormSelect<FormValues>
              control={control}
              name="problemCategory"
              label="PROBLEM CATEGORY"
              options={complaintOptions}
              placeholder="Select One Option"
              renderSelected={(value: string) => value}
            />

            <FormTextArea<FormValues>
              control={control}
              name="problemDescription"
              label="DESCRIPTION OF PROBLEM"
              minRows={3}
              maxRows={5}
            />

            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '.75rem',
                my: 2
              }}
            >
              <Button
                color="grey"
                transparent
                size="lg"
                text="Cancel"
                styleOverrides={{
                  borderRadius: '.5rem',
                  border: '.094rem solid #F2F4F7',
                  color: '#475467',
                  width: '50%'
                }}
                onClick={onClose}
              />

              <Button
                color="primary"
                size="lg"
                text={'Report Problem'}
                styleOverrides={{
                  borderRadius: '.5rem',
                  width: '50%'
                }}
                submit
                disabled={!isValid}
                loadingIcon={isSubmitting || isLoading}
              />
            </Stack>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default ReportProblemModal;
