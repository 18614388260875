import React, { useState } from 'react';
import BarChart from '../../../../components/charts/barchart';
import { Box } from '@mui/material';
import { DateRangeType, Nav } from './chartsNav';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import _ from 'lodash';

const defaultDateRange = getDateRange('This Month');

const TotalApprovalChart = () => {
  const [, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const dummyData: { name: string; total_value: number }[] = [
    { name: 'Hamala', total_value: 50 },
    { name: 'Dealer B', total_value: 42 },
    { name: 'Ilupeju', total_value: 38 },
    { name: 'ChemTech', total_value: 46 },
    { name: 'Deji-Manifest', total_value: 34 },
    { name: 'NaijaCement', total_value: 11 },
    { name: 'Milo', total_value: 29 },
    { name: 'MoshPharmacy', total_value: 27 },
    { name: 'InduPharmacy', total_value: 35 },
    { name: 'Nestle', total_value: 43 }
  ];

  const labels = dummyData.map(item =>
    _.truncate(item.name, { length: 10, omission: '...' })
  );
  const chartDataset = [
    {
      data: dummyData.map(item => item.total_value),
      label: 'Top Sales'
    }
  ];

  return (
    <Box
      sx={{
        marginTop: '2rem',
        gap: '1.75rem',
        width: 'inherit',
        height: '25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1.8rem',
        boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
      }}
    >
      <Nav
        header="Total Approvals Pending per customer"
        caption="See all workflows that are still in progress"
        showButton={true}
        setDateRange={setDateRange}
      />
      <BarChart
        labels={labels}
        datasets={chartDataset}
        disableCurrencyFormat={true}
      />
    </Box>
  );
};

export default TotalApprovalChart;
