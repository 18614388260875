import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { Box } from '@mui/material';
import { formatLargeAmount } from 'utilities/helperFunc/formatter';
import { colorTheme } from 'themes/colors';
import _ from 'lodash';

interface BarChartProps {
  labels: string[];
  datasets: { data: number[]; label: string }[];
  options?: ChartOptions<'bar'>;
  horizontal?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  disableCurrencyFormat?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  labels,
  datasets,
  options,
  horizontal = false,
  backgroundColor = colorTheme.primary50,
  borderColor = colorTheme.primary300,
  disableCurrencyFormat = false
}) => {
  const truncateLabel = (label: string) =>
    _.truncate(label, { length: 8, omission: '...' });

  const chartData: ChartData<'bar'> = {
    labels: labels.map(label => truncateLabel(label)),
    datasets: datasets.map(dataset => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor,
      borderColor,
      borderWidth: 1,
      borderRadius: { topLeft: 4, topRight: 4 }
    }))
  };

  const defaultOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: horizontal ? 'y' : 'x',
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
        border: {
          display: true,
          color: '#E0E0E0'
        },
        ticks: {
          callback: function (value, index) {
            if (!horizontal) {
              return _.truncate(labels[index], { length: 12, omission: '..' });
            }
            return disableCurrencyFormat
              ? value
              : formatLargeAmount(value as number, true, true);
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          drawTicks: true,
          tickLength: 10,
          tickWidth: 0,
          tickColor: '#667085'
        },
        border: {
          dash: [6],
          display: false,
          color: '#E0E0E0'
        },
        ticks: {
          callback: function (value, index) {
            if (!horizontal) {
              return disableCurrencyFormat
                ? value
                : formatLargeAmount(value as number, true, true);
            }
            return truncateLabel(labels[index]);
          }
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        titleAlign: 'center',
        footerAlign: 'center'
      }
    },
    ...options
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '85%' }}>
      <Bar data={chartData} options={defaultOptions} />
    </Box>
  );
};

export default BarChart;
