import React, { useState } from 'react';
import waves from 'assets/png/waves.png';
import SideImage from 'assets/png/onboarding-gradient.png';
import { theme, Nohemi } from 'themes/theme';
import { Grid, Box, Typography } from '@mui/material';
import OnboardingContextWrapper from 'pages/onboarding/context/onboardingContext';
import OnboardingNav from 'pages/onboarding/common/onboardingNav';
import OnboardingSidenav from 'pages/onboarding/common/onboardingSidenav';
import defaultLogo from 'assets/custom-svg/logo.svg';

const OnboardingLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [sideNavMargin, setSideNavMargin] = useState<string>('0%');
  const [mainContentWidth, setMainContentWidth] = useState<string>('75%');

  const logStep = (step: number) => {
    if (step > 3) {
      setSideNavMargin('-26%');
      setMainContentWidth('100%');
    } else {
      setSideNavMargin('0%');
      setMainContentWidth('75%');
    }
  };

  return (
    <Grid
      container
      sx={{
        height: 'inherit',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.common.white
      }}
    >
      <OnboardingContextWrapper>
        <Box
          sx={{
            width: '25%',
            height: 'inherit',
            marginLeft: sideNavMargin,
            transition: 'margin-left 0.5s ease-in-out',
            backgroundImage: `url(${SideImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'white',
            padding: '2rem 1.6rem 3rem 1.6rem',
            textAlign: 'center',
            position: 'relative'
          }}
        >
          <Box
            component="img"
            src={defaultLogo}
            sx={{
              position: 'absolute',
              top: '2.4375rem',
              left: '1.5rem',
              width: '2.8125rem',
              height: 'auto'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '7.95rem',
              width: '90%',
              height: 'auto',
              marginLeft: '0'
            }}
          >
            <Box sx={{ display: 'block', textAlign: 'left' }}>
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="display01Bold"
                  sx={{ width: '100%', fontFamily: Nohemi }}
                >
                  Future Proofing African Businesses
                </Typography>
              </Box>
              <Box sx={{ width: '100%', marginBottom: '1.777rem' }}>
                <Typography variant="bodyOnboardingsmall">
                  Building operation systems that empower Nigerian and African
                  businesses to thrive
                </Typography>
              </Box>
            </Box>

            <OnboardingSidenav logStep={logStep} />
          </Box>
        </Box>

        <Box
          sx={{
            height: 'inherit',
            width: mainContentWidth,
            backgroundImage: `url(${waves})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: `${theme.palette.common.layoutBackground}`,
            transition: 'width 0.5s ease-in-out'
          }}
        >
          <OnboardingNav />
          {children}
        </Box>
      </OnboardingContextWrapper>
    </Grid>
  );
};

export default OnboardingLayout;
