import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { Table } from 'components/table';

import { WorkflowSchemaApiData } from 'services/types/workflow';
import { WithIdTransformed } from 'services/types/common';
import { WorkflowUseCaseApiData } from 'services/api/workflowApi';
import { formattedDate } from 'utilities/helperFunc/formatter';

interface Workflow {
  name: string;
  use_case: string;
  description: string;
  created_by: string;
  created_on: string;
  steps: string;
}

const columnHelper = createColumnHelper<Workflow>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('created_by', {
    header: 'Created By',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('steps', {
    header: 'Steps',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('created_on', {
    header: 'Created On',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<Workflow>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

export type PublishedWorkflowsTableProps = {
  workflows: WithIdTransformed<WorkflowSchemaApiData>;
  description: WorkflowUseCaseApiData[];
  loadingTable: boolean;
};

const DraftWorkflowsTable: React.FC<PublishedWorkflowsTableProps> = ({
  workflows,
  description,
  loadingTable
}) => {
  const tableData: Workflow[] = [];
  const navigate = useNavigate();
  Object.values(workflows).forEach(workflow => {
    const descriptionItem = description.find(
      element => element.value === workflow.use_case
    );
    return tableData.push({
      name: _.startCase(workflow.use_case),
      use_case: workflow.use_case,
      description: descriptionItem ? descriptionItem.description : '-',
      created_by: workflow.published_by
        ? `${workflow.published_by.firstname} ${workflow.published_by.lastname}`
        : '-',
      steps: `${Object.values(workflow.steps).length} Required`,
      created_on: workflow.published_on
        ? formattedDate(`${workflow.published_on}`, 'Do MMM, YYYY hh:ma')
        : '-'
    });
  });

  return (
    <Table<Workflow>
      hover
      columns={columns}
      data={tableData}
      onClickRow={(rowData: Workflow) => {
        navigate(
          `/workflows-builder?create=false&workflowUseCase=${rowData.use_case}`
        );
      }}
      enableFilter={false}
      filterColumns={['name']}
      searchPlaceholderText="Search for Draft Workflows"
      loadingTable={loadingTable}
    />
  );
};

export default DraftWorkflowsTable;
