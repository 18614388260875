import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box,
  Stack,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import BarChart from './barchart';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { Button } from '../button';

interface Receipt {
  count: number;
  days: number;
}

interface CompanyData {
  company: string;
  receipts: Receipt[];
}

interface HeatChartProps {
  data: CompanyData[];
}

const HeatChart: React.FC<HeatChartProps> = ({ data }) => {
  const [selectedRange, setSelectedRange] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (range: string | null) => {
    setSelectedRange(range);
    setAnchorEl(null);
  };

  const handleRevertToHeatmap = () => {
    setSelectedRange(null);
  };

  const processSeries = (data: CompanyData[], name: string, color: string) => {
    const companies = data.map(item => item.company);
    let dayRange = { from: 0, to: 0 };
    if (name === '30 days') dayRange = { from: 1, to: 30 };
    if (name === '90 days') dayRange = { from: 31, to: 90 };
    if (name === '180 days') dayRange = { from: 91, to: 180 };

    return {
      name,
      data: companies.map(company => {
        const companyData = data.find(item => item.company === company);
        const totalCount = companyData?.receipts
          .filter(
            receipt =>
              receipt.days >= dayRange.from && receipt.days <= dayRange.to
          )
          .reduce((acc, curr) => acc + curr.count, 0);
        return { x: company, y: totalCount || null };
      }),
      color
    };
  };

  const series = [
    processSeries(data, '30 days', '#C3D7FD'),
    processSeries(data, '90 days', '#FFBA49'),
    processSeries(data, '180 days', '#D82E2F')
  ];

  const heatmapOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'heatmap',
      toolbar: { show: false }
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0,
        colorScale: {
          ranges: [
            { from: 0, to: 0, color: '#C3D7FD', name: '30 days' },
            { from: 1, to: 1, color: '#FFBA49', name: '90 days' },
            { from: 2, to: 2, color: '#D82E2F', name: '180 days' }
          ]
        },
        useFillColorAsStroke: false
      }
    },
    fill: { opacity: 1 },
    dataLabels: { enabled: true },
    xaxis: { type: 'category', labels: { show: true } },
    grid: {
      show: true,
      borderColor: '#E0E0E0',
      strokeDashArray: 5,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: true } }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: val => (val === null ? 'No data' : `${val} product receipts`)
      }
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } }
    },
    legend: { show: false }
  };

  const selectedSeries = series.find(s => s.name === `${selectedRange} days`);

  const barChartData = selectedSeries
    ? {
        labels: selectedSeries.data.map(item => item.x),
        datasets: [
          {
            label: selectedSeries.name,
            data: selectedSeries.data.map(item => item.y || 0)
          }
        ]
      }
    : { labels: [], datasets: [] };

  return (
    <Box sx={{ width: '100%', height: '550px', position: 'relative' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Box
          sx={{
            position: 'relative',
            top: '.5rem',
            mr: '3rem',
            zIndex: 1,
            display: isSmallScreen ? 'none' : 'block',
            ...(selectedRange && { display: 'none' })
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {[
              { label: '30 days', color: '#C3D7FD' },
              { label: '90 days', color: '#FAD17E' },
              { label: '180 days', color: '#E75D65' }
            ].map(item => (
              <Stack
                key={item.label}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    bgcolor: item.color,
                    borderRadius: '50%'
                  }}
                />
                <Typography variant="body2">{item.label}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Box>
            <Button
              color="grey"
              text="Filter"
              transparent
              icon={ChevronDown}
              iconPosition="end"
              size="md"
              onClick={handleFilterClick}
              styleOverrides={{ marginRight: '0.5rem' }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleFilterClose(null)}
            >
              <MenuItem onClick={() => handleFilterClose('30')}>
                30 days
              </MenuItem>
              <MenuItem onClick={() => handleFilterClose('90')}>
                90 days
              </MenuItem>
              <MenuItem onClick={() => handleFilterClose('180')}>
                180 days
              </MenuItem>
            </Menu>
          </Box>
          {selectedRange && (
            <Button
              outline={true}
              color="primary"
              text="Back"
              size="md"
              onClick={handleRevertToHeatmap}
            />
          )}
        </Box>
      </Box>
      {selectedRange ? (
        <BarChart
          labels={barChartData.labels}
          datasets={barChartData.datasets}
          options={{ indexAxis: 'x' }}
          backgroundColor="#FEF8EC"
          borderColor="#FAD17E"
        />
      ) : (
        <Chart
          options={heatmapOptions}
          series={series}
          type="heatmap"
          height="500"
        />
      )}
    </Box>
  );
};

export default HeatChart;
