import React from 'react';

import { Box, Checkbox as MUICheckbox, SvgIcon } from '@mui/material';

export const UnChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          fill="white"
          stroke="#EAECF0"
        />
      </svg>
    </SvgIcon>
  );
};

export const Checked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="4" fill="#2563EB" />
        <path
          d="M6.11133 9.99995L8.88911 12.7777L14.4447 7.22217"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export function IndeterminateCheckbox({
  indeterminate,
  checked,
  disabled,
  onChange
}: {
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
}) {
  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <MUICheckbox
      inputRef={ref}
      sx={{
        cursor: 'pointer',
        width: '1.25rem',
        height: '1.25rem',
        borderRadius: '.625rem'
      }}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      icon={<UnChecked />}
      checkedIcon={<Checked />}
    />
  );
}

export function OptionsButton() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.25rem',
        borderWidth: '.063rem',
        borderStyle: 'solid',
        borderColor: '#FFF',
        '&:hover': {
          background: '#F5F6F7',
          cursor: 'pointer',
          borderRadius: '.5rem',
          borderColor: '#F5F6F7'
        }
      }}
    >
      <SvgIcon inheritViewBox>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 7.99984C6.66667 7.26346 7.26362 6.6665 8 6.6665C8.73638 6.6665 9.33333 7.26346 9.33333 7.99984C9.33333 8.73622 8.73638 9.33317 8 9.33317C7.26362 9.33317 6.66667 8.73622 6.66667 7.99984Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3333 7.99984C11.3333 7.26346 11.9303 6.6665 12.6667 6.6665C13.403 6.6665 14 7.26346 14 7.99984C14 8.73622 13.403 9.33317 12.6667 9.33317C11.9303 9.33317 11.3333 8.73622 11.3333 7.99984Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.99984C2 7.26346 2.59695 6.6665 3.33333 6.6665C4.06971 6.6665 4.66667 7.26346 4.66667 7.99984C4.66667 8.73622 4.06971 9.33317 3.33333 9.33317C2.59695 9.33317 2 8.73622 2 7.99984Z"
            fill="#475467"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

export function CautionButton() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.25rem',
        borderWidth: '.063rem',
        borderStyle: 'solid',
        borderColor: '#FFF',
        '&:hover': {
          background: '#F5F6F7',
          cursor: 'pointer',
          borderRadius: '.5rem',
          borderColor: '#F5F6F7'
        }
      }}
    >
      <SvgIcon inheritViewBox>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8143 1.66837C12.297 1.43834 11.7064 1.43834 11.1891 1.66837C10.7896 1.84602 10.53 2.15894 10.3494 2.41391C10.1719 2.66461 9.98175 2.99303 9.77267 3.35422L1.50552 17.6339C1.29561 17.9964 1.10486 18.3258 0.975515 18.6054C0.844065 18.8895 0.701461 19.2714 0.74691 19.7074C0.805721 20.2715 1.10126 20.7841 1.55998 21.1176C1.91447 21.3753 2.31647 21.4433 2.62823 21.4719C2.93498 21.5 3.31563 21.5 3.73452 21.5H20.2688C20.6877 21.5 21.0684 21.5 21.3751 21.4719C21.6869 21.4433 22.0889 21.3753 22.4434 21.1176C22.9021 20.7841 23.1976 20.2715 23.2565 19.7074C23.3019 19.2714 23.1593 18.8895 23.0278 18.6054C22.8985 18.3258 22.7078 17.9964 22.4979 17.6339L14.2307 3.35419C14.0216 2.99301 13.8315 2.66459 13.6539 2.41391C13.4733 2.15894 13.2138 1.84602 12.8143 1.66837ZM13.0017 9C13.0017 8.44772 12.554 8 12.0017 8C11.4494 8 11.0017 8.44772 11.0017 9V13C11.0017 13.5523 11.4494 14 12.0017 14C12.554 14 13.0017 13.5523 13.0017 13V9ZM12.0017 16C11.4494 16 11.0017 16.4477 11.0017 17C11.0017 17.5523 11.4494 18 12.0017 18H12.0117C12.564 18 13.0117 17.5523 13.0117 17C13.0117 16.4477 12.564 16 12.0117 16H12.0017Z"
            fill="#E1343F"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}
