import React, { useState } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ReactComponent as Trash } from 'assets/svg/trash.svg';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as VerticalMenu } from 'assets/svg/vertical-menu.svg';
import { SvgWrapper } from 'components/svg';
import { WorkflowStepCommentsApiData } from 'services/types/workflow';
import CommentMenu from './commentMenu';

dayjs.extend(relativeTime);

type WorkflowViewerReplyCommentCardProps = {
  reply: WorkflowStepCommentsApiData;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  setAddComment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteComment: () => void;
  addReply: () => void;
};

const WorkflowViewerReplyCommentCard = ({
  reply,
  addReply,
  setCommentId,
  setNewComment,
  setAddComment,
  setIsEdit,
  handleDeleteComment
}: WorkflowViewerReplyCommentCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const isWithinTenMinutes =
    dayjs().diff(dayjs(reply.updated_at || reply.created_at), 'minute') <= 10;

  const handleEdit = () => {
    setCommentId(reply.comment_id);
    setNewComment(reply.comment);
    setIsEdit(true);
    setAddComment(true);
  };

  return (
    <Stack
      direction="column"
      padding="0rem 1.2rem"
      alignItems="center"
      sx={{
        background: '#F8FBFE',
        border: '.064rem solid #F5F6F7',
        borderTop: 'none',
        borderBottom: 'none',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginY: '0.75rem',
          width: '100%'
        }}
      >
        <Stack direction="row" width="auto" gap=".25rem" alignItems="center">
          <Avatar
            src={reply.user.profile_picture}
            style={{ width: '2rem', height: '2rem' }}
          />
          <Stack direction="column" justifyContent="flex-start">
            <Typography variant="bodyMediumSemibold" color="#475467">
              {reply.user.full_name}
            </Typography>
            <Typography variant="captionXSmall" color="#98A2B3">
              {dayjs(reply.updated_at || reply.created_at).fromNow()}
            </Typography>
          </Stack>
        </Stack>
        {isWithinTenMinutes && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              setCommentId(reply.comment_id);
              setAnchorEl(e.currentTarget);
            }}
            aria-describedby={id}
          >
            <SvgWrapper
              icon={VerticalMenu}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#667085' }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: 'inherit',
          flexWrap: 'wrap'
        }}
      >
        <Typography variant="bodyMediumMedium" color="#667085">
          {reply.comment}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          cursor: 'pointer',
          gap: '0.5rem',
          margin: '.75rem'
        }}
        onClick={addReply}
      >
        <Typography variant="bodyMediumSemibold" color="#667085">
          Reply
        </Typography>
      </Box>

      <CommentMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        menuOptions={[
          {
            title: 'Edit',
            image: Edit,
            onClick: handleEdit
          },
          {
            title: 'Delete',
            image: Trash,
            onClick: handleDeleteComment
          }
        ]}
      />
    </Stack>
  );
};

export default WorkflowViewerReplyCommentCard;
