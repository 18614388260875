import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import WorkflowsViewerStepsGroup from './workflowViewerStepsGroup';

import { shadowsTheme } from 'themes/shadows';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';
import { ReactComponent as FourRoundCornersIcon } from 'assets/svg/four-round-corners.svg';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef
} from 'react-zoom-pan-pinch';
import _ from 'lodash';

import { WorkflowTargetObject } from 'services/enums/workflows';
import { useAppSelector } from 'services/hook';
import { WorkflowApiData, WorkflowStepApiData } from 'services/types/workflow';
import WorkflowViewerCommentsLayout from './workflowViewerComments/viewerCommentsLayout';

type WorkflowsViewerProps = {
  workflow: WorkflowApiData;
  workflowTargetObject: WorkflowTargetObject;
  workflowTargetObjectId: string;
  invalidateTag: string;
  invalidateTagId: string | number;
};

const WorkflowsViewer = ({
  workflow,
  workflowTargetObject,
  workflowTargetObjectId,
  invalidateTag,
  invalidateTagId
}: WorkflowsViewerProps) => {
  const maxZoom = 1;
  const minZoom = 0.25;

  const [currentZoom, setCurrentZoom] = useState<number>(1);
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
  const [activeStep, setActiveStepId] = useState<WorkflowStepApiData>(
    {} as WorkflowStepApiData
  );
  const [openComments, setOpenComments] = useState<boolean>(false);
  const { FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS } = useAppSelector(
    state => state.featureFlags
  );

  const displayComments = (stepId: string) => {
    if (stepId !== selectedStepId) {
      const newActiveStep = Object.values(workflow.steps).find(
        step => step.step_id === stepId
      );

      setSelectedStepId(stepId);
      setActiveStepId(newActiveStep || ({} as WorkflowStepApiData));
    }
  };

  const getActiveStep = (steps: WorkflowStepApiData[]) => {
    if (steps) {
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        if (step.step_outcome === null) {
          return i === 0 ? step : null;
        }
        if (step.step_outcome === false) {
          return step;
        }
        if (step.step_outcome === true) {
          return steps[i + 1] || null;
        }
      }
    }
    return null;
  };

  const handleZoomed = (ref: ReactZoomPanPinchRef) => {
    setCurrentZoom(ref.state.scale);
  };

  useEffect(() => {
    const currentStep: WorkflowStepApiData | null = getActiveStep(
      Object.values(workflow.steps)
    );

    if (currentStep) {
      setSelectedStepId(currentStep.step_id);
      setActiveStepId(currentStep);
    }
  }, [workflow]);

  return (
    <TransformWrapper
      initialScale={1}
      minScale={minZoom}
      maxScale={maxZoom}
      minPositionX={0}
      minPositionY={0}
      onZoomStop={handleZoomed}
      onTransformed={handleZoomed}
      wheel={{ disabled: true, excluded: ['input'] }}
      doubleClick={{ disabled: true }}
      panning={{ disabled: true, excluded: ['input'] }}
      pinch={{ disabled: true, excluded: ['input'] }}
    >
      {({ setTransform, resetTransform }) => (
        <Box
          sx={{
            mb: '3rem',
            height: 'inherit',
            overflow: 'hidden',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.75rem',
              border: '.063rem solid #F2F4F7',
              opacity: 0.8,
              backgroundColor: '#FFF',
              boxShadow: shadowsTheme.buttonShadow,
              padding: '0.75rem',
              width: '20%',
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 1
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setTransform(0, 0, Math.max(minZoom, currentZoom - 0.25), 0)
              }
            >
              <SvgWrapper
                icon={MinusIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3'
                }}
              />
            </Box>
            <Typography
              variant="bodyMediumSemibold"
              color="#475467"
              sx={{ marginX: '1rem' }}
            >
              {_.round(currentZoom * 100)}%
            </Typography>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setTransform(0, 0, Math.min(maxZoom, currentZoom + 0.25), 0)
              }
            >
              <SvgWrapper
                icon={PlusIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3',
                  marginRight: '1.75rem'
                }}
              />
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={() => resetTransform(0)}>
              <SvgWrapper
                icon={FourRoundCornersIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3'
                }}
              />
            </Box>
          </Box>
          <TransformComponent
            wrapperStyle={{
              width: 'auto',
              height: 'inherit',
              position: 'absolute',
              overflowY: 'auto',
              top: 0,
              left: 0,
              right: 0,
              margin: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              transition: 'transform 0.2s ease-in-out'
            }}
            contentStyle={{
              width: '100%',
              height: 'inherit',
              transform: 'none',
              transition: 'transform 0.2s ease-in-out'
            }}
          >
            <Box
              sx={{
                width: '100%',
                mt: '3rem',
                overflowY: 'auto',
                msOverflowStyle: 'none',
                transition: 'transform 0.2s ease-in-out',
                scrollbarWidth: 'none',
                '&::WebkitScrollbar': {
                  display: 'none'
                }
              }}
            >
              <WorkflowsViewerStepsGroup
                workflow={workflow}
                workflowTargetObject={workflowTargetObject}
                workflowTargetObjectId={workflowTargetObjectId}
                invalidateTag={invalidateTag}
                invalidateTagId={invalidateTagId}
                setOpenComments={setOpenComments}
                displayComments={displayComments}
                selectedStepId={selectedStepId}
              />
            </Box>
          </TransformComponent>
          <Box
            sx={{
              width: 'auto',
              height: '100%',
              position: 'absolute',
              bottom: openComments ? undefined : 0,
              top: openComments ? -16 : undefined,
              right: openComments ? -16 : 0,
              display: 'flex',
              flexDirection: openComments ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              zIndex: 1
            }}
          >
            {FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS && (
              <WorkflowViewerCommentsLayout
                activeStep={activeStep}
                openComments={openComments}
                setOpenComments={setOpenComments}
              />
            )}
          </Box>
        </Box>
      )}
    </TransformWrapper>
  );
};

export default WorkflowsViewer;
