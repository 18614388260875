import React from 'react';
import { Stack, Box, Typography, Avatar } from '@mui/material';
import { ReactComponent as Logout } from 'assets/svg/logout.svg';
import { SvgWrapper } from 'components/svg';
import { useLogoutUserMutation } from 'services/api/authApi';
import { useAppDispatch, useAppSelector } from 'services/hook';
import { setUserLogout } from 'services/slices/authSlice';
import { useGetOrganizationQuery } from 'services/api/organizationApi';
import NavRoutes from './navRoutes';
import { getRoutesForNav } from 'routes';

const SideBar: React.FC = () => {
  const authState = useAppSelector(state => state.auth);
  const flags = useAppSelector(state => state.featureFlags);
  const { data: organizationData } = useGetOrganizationQuery(
    authState.organizationId
  );
  const [logoutUser] = useLogoutUserMutation();
  const dispatch = useAppDispatch();

  const routes = getRoutesForNav(authState, flags);

  const onLogoutClick = () => {
    logoutUser({})
      .unwrap()
      .then(() => {
        // api.dispatch(api.util.resetApiState()); Not sure we actually need this but will leave for now
        dispatch(setUserLogout());
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          height: '-webkit-fill-available',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: {
            md: '.1rem 0.5rem',
            lg: '1.25rem 0.75rem'
          },
          padding: '0 0.75rem'
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          gap=".5rem"
          sx={{
            mt: {
              md: '.5rem',
              lg: '2rem'
            }
          }}
        >
          <Typography variant="inputLabel" color="#98A2B3">
            Organization
          </Typography>
          <Stack direction="row" gap=".5rem" alignItems="center">
            <Avatar
              src={organizationData?.logo}
              sx={{
                width: '1.75rem',
                height: '1.75rem'
              }}
            />
            <Typography variant="bodyMediumMedium" color="#475467">
              {organizationData?.name || ''}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            mt: {
              md: '.5rem',
              lg: '1.25rem'
            }
          }}
        >
          <Typography variant="inputLabel" color="#98A2B3">
            Menu
          </Typography>
        </Box>

        <Stack
          display="flex"
          direction="column"
          justifyContent="space-between"
          sx={{
            width: '100%',
            height: 'inherit'
          }}
        >
          <NavRoutes routes={routes} />

          <Stack
            display="flex"
            direction="row"
            alignItems="center"
            width="inherit"
          >
            <Box
              onClick={() => onLogoutClick()}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '0.625rem',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#f4f5f7'
                },
                borderRadius: '0.5rem'
              }}
            >
              <Typography variant="bodyMediumSemibold" color="#475467">
                Log Out
              </Typography>

              <SvgWrapper
                height="1.5rem"
                width="1.5rem"
                icon={Logout}
                styleOverrides={{
                  fill: '#ffffff00',
                  stroke: '#475467'
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default SideBar;
