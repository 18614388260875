import React from 'react';
import HeatMap from '../../../../components/charts/heatMap';
import { Box, Stack, Typography } from '@mui/material';

const Branchheatmap: React.FC = () => {
  const dummyData = [
    {
      company: 'Ikeja A',
      receipts: [
        { count: 5, days: 15 },
        { count: 10, days: 120 },
        { count: 2, days: 95 }
      ]
    },
    {
      company: 'Lekki B',
      receipts: [
        { count: 7, days: 30 },
        { count: 4, days: 60 }
      ]
    },

    {
      company: 'Alimosho C',
      receipts: [
        { count: 1, days: 120 },
        { count: 7, days: 30 },
        { count: 3, days: 91 }
      ]
    },
    {
      company: 'Ikeja C',
      receipts: [
        { count: 1, days: 180 },
        { count: 3, days: 91 }
      ]
    },
    {
      company: 'Ikorodu A',
      receipts: [
        { count: 5, days: 15 },
        { count: 10, days: 120 },
        { count: 2, days: 95 }
      ]
    },
    {
      company: 'Meiran B',
      receipts: [
        { count: 6, days: 30 },
        { count: 4, days: 60 }
      ]
    },
    {
      company: 'Agege A',
      receipts: [
        { count: 5, days: 15 },
        { count: 10, days: 120 },
        { count: 2, days: 95 }
      ]
    },

    {
      company: 'Ikeja C',
      receipts: [
        { count: 1, days: 180 },
        { count: 3, days: 91 }
      ]
    },
    {
      company: 'Allen B',
      receipts: [
        { count: 7, days: 30 },
        { count: 4, days: 60 }
      ]
    }
  ];

  return (
    <Box
      position="relative"
      mt="3rem"
      border="solid .125rem #F5F6F7"
      padding="2.8rem"
      sx={{
        backgroundColor: '#fff'
      }}
      borderRadius="0.75rem"
    >
      <Stack direction="column" gap={0.5} position="absolute">
        <Typography variant="h7Semibold" color="#475467">
          Branch Receipt Age Analysis
        </Typography>

        <Typography variant="bodyMediumMedium" color="#98A2B3">
          View the age breakdown of all product receipts within a specific
          branch
        </Typography>
      </Stack>
      <HeatMap data={dummyData} />
    </Box>
  );
};

export default Branchheatmap;
