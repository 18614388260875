import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { theme } from 'themes/theme';
import { DateRangeType, Nav } from './chartsNav';
import BarChart from '../../../../components/charts/barchart';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

const defaultDateRange = getDateRange('This Month');

const TopCustomersChart = () => {
  const [, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const topCustomers = [
    { name: 'Dangote', value: 150 },
    { name: 'Mosh', value: 120 },
    { name: 'Milo', value: 100 },
    { name: 'NaijaCement', value: 90 },
    { name: 'Pharmacy', value: 80 }
  ];

  const labels = topCustomers.map(item => item.name);
  const chartDataset = [
    {
      data: topCustomers.map(item => item.value),
      label: 'Top Customers'
    }
  ];

  return (
    <Box
      sx={{
        width: '48%',
        height: '31.25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1rem'
      }}
    >
      <Nav
        header="Top Customers"
        caption="Customers with most endorsements"
        setDateRange={setDateRange}
      />
      <BarChart
        labels={labels}
        datasets={chartDataset.map(dataset => ({
          ...dataset,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light
        }))}
        horizontal={true}
      />
    </Box>
  );
};

export default TopCustomersChart;
