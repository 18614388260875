import React, { useState } from 'react';
import {
  TableBody as MUITableBody,
  TableRow,
  TableCell,
  Box,
  Typography
} from '@mui/material';
import { ColumnDef, Row, Table, flexRender } from '@tanstack/react-table';
import { ReactComponent as ChevronUpIcon } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevronDown.svg';
import { ReactComponent as Empty } from 'assets/custom-svg/table-empty-state.svg';
import {
  OptionsButton,
  IndeterminateCheckbox,
  CautionButton
} from '../tableButtons';
import TableMenu from './tableMenu';
import type { TableMenuProps } from './tableMenu';
import { SvgWrapper } from 'components/svg';
import NestedTable from './nestedTable';
import { CustomSkeleton } from '../../../skeleton/tableskeletonLoader';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { useAppSelector } from 'services/hook';
import { OrganizationType } from 'services/enums/organization';

interface TableBodyProps<TableDataT> {
  table: Table<TableDataT>;
  optionsButton?: boolean;
  rowShouldHover?: boolean;
  onClickRow?: (rowData: TableDataT) => void;
  showMenu?: boolean;
  showCaution?: boolean;
  loading?: boolean;
  menuItemProps:
    | TableMenuProps<TableDataT>['menuItemProps'] // Static array
    | ((row: Row<TableDataT>) => TableMenuProps<TableDataT>['menuItemProps']); // Function;
  columns: ColumnDef<TableDataT>[];
  tableTitleType?: 'product-receipt-history-table';
}

export default function TableBody<TableDataT>({
  table,
  optionsButton,
  rowShouldHover,
  showMenu,
  menuItemProps,
  onClickRow,
  showCaution,
  loading,
  columns,
  tableTitleType
}: TableBodyProps<TableDataT>) {
  const { organizationType } = useAppSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null);
  const [selectedRowMenu, setSelectedRowMenu] =
    useState<Row<TableDataT> | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const onClickRowCallback = (rowData: TableDataT) => {
    if (onClickRow) {
      onClickRow(rowData);
    }
  };

  return (
    <MUITableBody sx={{ body: '#F5F6F7' }}>
      {loading ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <CustomSkeleton
                rowCount={6}
                columns={columns as ColumnDef<TableDataT>[]}
              />
            </Box>
          </TableCell>
        </TableRow>
      ) : table.getRowModel().rows.length === 0 ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '6rem 0rem'
              }}
            >
              <SvgWrapper
                height="4.25rem"
                width="4.25rem"
                icon={Empty}
                styleOverrides={{ fill: 'none', margin: '0 auto' }}
              />
              <Typography variant="bodyMediumSemibold" color="#475467">
                No Data Found
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        table.getRowModel().rows.map(row => {
          const rowData = row.original as MiniProductReceiptApiData;
          const hasUnresolvedComplaint =
            rowData.complaints?.length > 0 &&
            rowData.complaints[rowData.complaints.length - 1].resolved_on ===
              null;
          return (
            <React.Fragment key={row.id}>
              <TableRow
                sx={{
                  backgroundColor: '#fff',
                  ...(rowShouldHover && {
                    '&:hover': {
                      background: '#FCFCFD',
                      cursor: 'pointer'
                    }
                  }),
                  borderBottom: '.0625rem solid #F5F6F7',
                  position: 'relative'
                }}
                onClick={() =>
                  !optionsButton ? onClickRowCallback(row.original) : null
                }
              >
                <TableCell
                  sx={{
                    borderBottom: 'none',
                    display: 'table-cell',
                    width: '5rem',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    left: '0rem',
                    opacity: 1,
                    position: 'sticky',
                    zIndex: 1,
                    backgroundColor: 'inherit',
                    paddingRight: '0rem'
                  }}
                >
                  <Box
                    sx={{
                      height: 'inherit',
                      width: '4rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      backgroundColor: row.getCanExpand() ? '#fff' : undefined
                    }}
                  >
                    <IndeterminateCheckbox
                      {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler()
                      }}
                    />

                    {row.getCanExpand() && (
                      <Box
                        onClick={row.getToggleExpandedHandler()}
                        sx={{
                          cursor: 'pointer',
                          ml: '1rem',
                          position: 'sticky',
                          zIndex: 1
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <Box
                            component="span"
                            sx={{
                              width: '1.25rem',
                              height: '1.25rem',
                              borderRadius: '6.25rem',
                              background: '#F9FAFC',
                              display: 'flex',
                              justifyContent: 'center',
                              mr: '.5rem',
                              boxShadow:
                                '0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)'
                            }}
                          >
                            <SvgWrapper
                              icon={ChevronUpIcon}
                              width="0.83331rem"
                              height="0.83331rem"
                              styleOverrides={{
                                fill: 'none',
                                stroke: '#98A2B3'
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            component="span"
                            sx={{
                              width: '1.25rem',
                              height: '1.25rem',
                              borderRadius: '6.25rem',
                              background: '#F9FAFC',
                              display: 'flex',
                              justifyContent: 'center',
                              mr: '.5rem',
                              boxShadow:
                                '0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)'
                            }}
                          >
                            <SvgWrapper
                              icon={ChevronDownIcon}
                              width="1.25rem"
                              height="1.25rem"
                              styleOverrides={{
                                fill: 'none',
                                stroke: '#98A2B3'
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </TableCell>

                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell
                      key={cell.id}
                      sx={{
                        width: `${cell.column.getSize() / 16}rem`,
                        borderBottom: 'none',
                        zIndex: 0,
                        position: 'relative',
                        ...(cell.column.columnDef.enablePinning && {
                          left: '5rem',
                          opacity: 1,
                          position: 'sticky',
                          zIndex: 1,
                          backgroundColor: 'inherit',
                          boxShadow: '-2px 0 2px -2px lightgray inset'
                        })
                      }}
                    >
                      {loading ? (
                        <CustomSkeleton
                          rowCount={6}
                          columns={columns as ColumnDef<TableDataT>[]}
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </TableCell>
                  );
                })}
                {(organizationType === OrganizationType.MERCHANT ||
                  organizationType === OrganizationType.LENDER) &&
                  showCaution && (
                    <TableCell
                      sx={{
                        borderBottom: '.063rem solid #F5F6F7',
                        marginRight: '-1rem',
                        right: '4.14rem',
                        opacity: 0.95,
                        position: 'sticky',
                        width: '3.125rem',
                        zIndex: 3,
                        backgroundColor: '#fff',
                        cursor: 'pointer'
                      }}
                      aria-describedby={id}
                    >
                      {(() => {
                        return hasUnresolvedComplaint ? (
                          <CautionButton />
                        ) : null;
                      })()}
                    </TableCell>
                  )}
                {tableTitleType && (
                  <TableCell
                    sx={{
                      borderBottom: '.063rem solid #F5F6F7',
                      marginRight: '.9rem',
                      right: '0rem',
                      opacity: 0.95,
                      position: 'sticky',
                      width: '3.125rem',
                      zIndex: 1,
                      backgroundColor: '#fff',
                      cursor: 'pointer'
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLTableCellElement, MouseEvent>
                    ) => {
                      if (hasUnresolvedComplaint) {
                        return showMenu
                          ? (setAnchorEl(e.currentTarget),
                            setSelectedRowMenu(row))
                          : onClickRowCallback(row.original);
                      }
                    }}
                    aria-describedby={id}
                  >
                    {hasUnresolvedComplaint ? (
                      <OptionsButton />
                    ) : (
                      <Box
                        sx={{
                          width: '3.125rem',
                          height: '1.25rem',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)'
                        }}
                      />
                    )}
                  </TableCell>
                )}

                {optionsButton && (
                  <TableCell
                    sx={{
                      borderBottom: '.063rem solid #F5F6F7',
                      marginRight: '.9rem',
                      right: '0rem',
                      opacity: 0.95,
                      position: 'sticky',
                      width: '3.125rem',
                      zIndex: 1,
                      backgroundColor: '#fff',
                      cursor: 'pointer'
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLTableCellElement, MouseEvent>
                    ) => {
                      return showMenu
                        ? (setAnchorEl(e.currentTarget),
                          setSelectedRowMenu(row))
                        : onClickRowCallback(row.original);
                    }}
                    aria-describedby={id}
                  >
                    <OptionsButton />
                  </TableCell>
                )}
              </TableRow>

              {row.getIsExpanded() && (
                <NestedTable
                  row={row}
                  optionsButton={optionsButton}
                  onClickRowCallback={onClickRowCallback}
                />
              )}

              {selectedRowMenu && (
                <TableMenu<TableDataT>
                  row={selectedRowMenu}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  menuItemProps={
                    typeof menuItemProps === 'function'
                      ? menuItemProps(selectedRowMenu) // If it's a function, call it
                      : menuItemProps
                  }
                />
              )}
            </React.Fragment>
          );
        })
      )}
    </MUITableBody>
  );
}
