import React from 'react';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { NotificationApiData } from 'services/types/notifications';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import _ from 'lodash';
import { useGetNotificationsQuery } from 'services/api/notificationApi';
import { formattedDate } from 'utilities/helperFunc/formatter';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { WithIdTransformed } from 'services/types/common';
import NoNotifications from './noNotifications';

interface CategorizedNotifications {
  today: NotificationApiData[];
  yesterday: NotificationApiData[];
  last7Days: NotificationApiData[];
  others: NotificationApiData[];
}

const renderNotifications = (notifications: NotificationApiData[]) => {
  dayjs.extend(relativeTime);

  return (
    <>
      <List>
        {notifications.map(notification => (
          <ListItem key={notification.id}>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width="100%"
              padding="0.25rem 0rem 0.75rem 0rem"
              borderBottom="0.063rem solid #F5F6F7"
            >
              <Stack
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flexGrow={1}
                ml="0.5rem"
                gap="0.25rem"
              >
                <Typography variant="bodyMediumMedium" color="#344054">
                  {_.capitalize(notification.text)}
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  {formattedDate(notification.created_at, 'ddd DD, YYYY')}
                </Typography>
              </Stack>

              <Stack
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
                gap="0.38rem"
              >
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="0.5rem"
                >
                  <SvgWrapper
                    icon={Dot}
                    width="0.5rem"
                    height="0.5rem"
                    styleOverrides={{ fill: '#04802E' }}
                  />
                  <SvgWrapper
                    icon={ChevronRightIcon}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: 'none', stroke: '#667085' }}
                  />
                </Stack>
                <Typography
                  variant="bodySmallRegular"
                  color="#98A2B3"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {dayjs(notification.created_at).fromNow()}
                </Typography>
              </Stack>
            </Stack>
          </ListItem>
        ))}
      </List>
    </>
  );
};

const GeneralNotifications = () => {
  const { data } = useGetNotificationsQuery({
    params: {
      include_seen: 'true'
    }
  });

  const categorizeNotifications = (
    notifications: WithIdTransformed<NotificationApiData>
  ) => {
    const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');
    const last7Days = dayjs().subtract(7, 'day');

    // Group notifications by time
    const categories: CategorizedNotifications = {
      today: [],
      yesterday: [],
      last7Days: [],
      others: []
    };

    Object.values(notifications).map(notification => {
      const notificationDate = dayjs(notification.created_at);

      if (notificationDate.isSame(today, 'day')) {
        categories.today.push(notification);
      } else if (notificationDate.isSame(yesterday, 'day')) {
        categories.yesterday.push(notification);
      } else if (
        notificationDate.isAfter(last7Days) &&
        notificationDate.isBefore(today)
      ) {
        categories.last7Days.push(notification);
      } else {
        categories.others.push(notification);
      }
    });

    return categories;
  };

  const notificationsData = data || {};
  const categorizedNotifications = categorizeNotifications(notificationsData);

  return (
    <Box style={{ width: '100%', height: 'inherit', paddingBottom: '4rem' }}>
      {/* Todays notifications */}
      {categorizedNotifications.today.length > 0 && (
        <>
          <Box padding="0rem 1.5rem 0.5rem 1.5rem">
            <Typography variant="captionSmall" color="#98A2B3">
              Today
            </Typography>
          </Box>
          {renderNotifications(categorizedNotifications.today)}
        </>
      )}

      {/* Yesterdays notifications */}
      {categorizedNotifications.yesterday.length > 0 && (
        <>
          <Box padding="0rem 1.5rem 0.5rem 1.5rem">
            <Typography variant="captionSmall" color="#98A2B3">
              Yesterday
            </Typography>
          </Box>
          {renderNotifications(categorizedNotifications.yesterday)}
        </>
      )}

      {/* Last 7 days notifications */}
      {categorizedNotifications.last7Days.length > 0 && (
        <>
          <Box padding="0rem 1.5rem 0.5rem 1.5rem">
            <Typography variant="captionSmall" color="#98A2B3">
              Last 7 days
            </Typography>
          </Box>
          {renderNotifications(categorizedNotifications.last7Days)}
        </>
      )}

      {/* Other notifications */}
      {categorizedNotifications.others.length > 0 && (
        <>
          <Box padding="0rem 1.5rem 0.5rem 1.5rem">
            <Typography variant="captionSmall" color="#98A2B3">
              Others
            </Typography>
          </Box>
          {renderNotifications(categorizedNotifications.others)}
        </>
      )}

      {/* No Notifications */}
      {Object.values(categorizedNotifications).every(
        notifications => notifications.length === 0
      ) && (
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <NoNotifications />
        </Box>
      )}
    </Box>
  );
};

export default GeneralNotifications;
