import React from 'react';
import { SearchBar } from 'components/search';
import logo from 'assets/png/fluxLogo-white.png';
import { useGetUserQuery } from 'services/api/userApi';

import {
  Stack,
  Toolbar,
  Box,
  Divider,
  Avatar,
  Typography
} from '@mui/material';
import { theme } from 'themes/theme';
import BreadCrumb from './breadCrumb';
import _ from 'lodash';

const AppNavBar = () => {
  const { data: userData } = useGetUserQuery({});
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        height: '3.5rem',
        background: '#07254A',
        borderBottom: `.063rem solid ${theme.palette.common.cardBorder}`,
        padding: {
          md: '.75rem 1.75rem',
          lg: '1rem 2rem',
          xl: '1rem 3rem'
        }
      }}
    >
      <Stack
        direction="row"
        gap=".5rem"
        alignItems="center"
        sx={{
          mr: {
            xs: '5%',
            sm: '5%',
            md: '7%',
            lg: '13%'
          }
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{ width: '1.81rem', height: '1.81rem' }}
        />
        <Typography variant="h6Bold" color="#fff">
          Flux
        </Typography>
      </Stack>
      <Box sx={{ width: '12rem' }}>
        <Box sx={{ position: 'absolute', top: '1.2rem' }}>
          <BreadCrumb />
        </Box>
      </Box>

      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingLeft: {
            sm: '0%',
            md: '10%',
            lg: '20%'
          }
        }}
      >
        <Box width="30rem" height="2.75rem" ml="3rem">
          <SearchBar
            size="lg"
            id="localSearch"
            options={[]}
            onChange={value => console.log(value)}
            placeholderText="Search Taficasa for product receipts, branches and teams"
            styleOverrides={{
              textField: {
                backgroundColor: '#1A3658',
                borderColor: 'transparent',
                '& .MuiInputBase-input::placeholder': {
                  color: '#D0D5DD'
                }
              }
            }}
          />
        </Box>

        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="right"
          width="auto"
        >
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.375rem',
              borderRadius: '0.125rem',
              margin: '0rem 1rem'
            }}
          />
          <Stack
            display="flex"
            direction="row"
            alignItems="center"
            width="inherit"
          >
            <Avatar
              src={userData?.user_profile.profile_picture}
              sx={{ width: '2rem', height: '2rem' }}
            />
            <Stack direction="column" sx={{ marginLeft: '0.5rem' }}>
              <Typography variant="bodyMediumSemibold" color="#D0D5DD">
                {_.truncate(
                  `${userData?.firstname || ''} ${userData?.lastname || ''}`,
                  { length: 20, omission: '...' }
                )}
              </Typography>
              <Typography variant="bodyMediumRegular" color="#667085">
                {_.truncate(userData?.email || '', {
                  length: 30,
                  omission: '...'
                })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default AppNavBar;
