import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { SvgWrapper } from 'components/svg';
import { shadowsTheme } from 'themes/shadows';
import { Chip } from 'components/chip';

type CommentSummaryProps = {
  displayComments: () => void;
  stepId: string | null;
  commentNo: number;
};

const CommentSummary = ({
  displayComments,
  stepId,
  commentNo
}: CommentSummaryProps) => {
  return (
    <Box
      sx={{
        width: 'inherit',
        backgroundColor: '#FFF',
        boxShadow: shadowsTheme.buttonShadow,
        padding: '.5rem .75rem',
        borderRadius: '0.75rem',
        border: '.0625rem solid #F2F4F7',
        cursor: stepId ? 'pointer' : 'not-allowed'
      }}
      onClick={displayComments}
    >
      <Stack
        gap="1rem"
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          gap=".2rem"
          width="auto"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <SvgWrapper
            icon={Dot}
            width="0.375rem"
            height="0.375rem"
            styleOverrides={{ fill: '#4649E3' }}
          />

          <Typography variant="bodyMediumSemibold" color="#667085">
            Comments
          </Typography>
        </Box>

        <Chip
          label={`${commentNo} ${commentNo === 1 ? 'Comment' : 'Comments'} `}
          size="sm"
        />
      </Stack>
    </Box>
  );
};

export default CommentSummary;
