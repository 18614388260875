import React, { useState } from 'react';
import BarChart from '../../../../components/charts/barchart';
import { Box } from '@mui/material';
import { DateRangeType, Nav } from './chartsNav';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import _ from 'lodash';

const defaultDateRange = getDateRange('This Month');

const TotalBranchApprovalchart = () => {
  const [, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const dummyData: { name: string; total_value: number }[] = [
    { name: 'Lekki', total_value: 20 },
    { name: 'Agege', total_value: 32 },
    { name: 'Ilupeju', total_value: 48 },
    { name: 'Mowe', total_value: 46 },
    { name: 'Jabi', total_value: 34 },
    { name: 'Ibadan', total_value: 31 },
    { name: 'Jos', total_value: 19 },
    { name: 'Ketu', total_value: 27 },
    { name: 'Mile 2', total_value: 35 },
    { name: 'Felele', total_value: 43 }
  ];

  const labels = dummyData.map(item =>
    _.truncate(item.name, { length: 10, omission: '...' })
  );
  const chartDataset = [
    {
      data: dummyData.map(item => item.total_value),
      label: 'Top Sales'
    }
  ];

  return (
    <Box
      sx={{
        marginTop: '2rem',
        gap: '1.75rem',
        width: 'inherit',
        height: '25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1.8rem',
        boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
      }}
    >
      <Nav
        header="Total Approvals Pending per branch"
        caption="See all workflows that are still in progress"
        showButton={true}
        setDateRange={setDateRange}
      />
      <BarChart
        labels={labels}
        datasets={chartDataset}
        disableCurrencyFormat={true}
      />
    </Box>
  );
};

export default TotalBranchApprovalchart;
