import React from 'react';
import { Box, CircularProgress, Stack, TextField } from '@mui/material';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Mention } from 'assets/svg/@.svg';
import { ReactComponent as Send } from 'assets/svg/send.svg';
import { ReactComponent as X } from 'assets/svg/x.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { fontFamily2 } from 'themes/theme';

type AddCommentProps = {
  addComment: boolean;
  newComment: string;
  setAddComment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
  setParentCommentId: React.Dispatch<React.SetStateAction<string>>;
  handleAddComment: () => void;
  handleEditComment: () => void;
  isLoading: boolean;
  editCheck: boolean;
};

const AddComment = ({
  addComment,
  setAddComment,
  newComment,
  setNewComment,
  setCommentId,
  setParentCommentId,
  setIsEdit,
  handleAddComment,
  handleEditComment,
  isLoading,
  editCheck
}: AddCommentProps) => {
  return (
    <Box
      padding=".75rem"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderTop: '.064rem solid #F5F6F7',
        background: '#ffffff',
        borderRadius: '0rem 0rem .75rem .75rem'
      }}
    >
      {addComment ? (
        <Stack spacing={2} sx={{ width: '100%' }}>
          <TextField
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                editCheck ? handleEditComment() : handleAddComment();
              }
            }}
            placeholder="Enter your comment"
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                padding: 0,
                fontFamily: fontFamily2,
                fontSize: '.875rem',
                fontColor: '#667085',
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent'
                }
              }
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            borderTop=".0625rem solid #F5F6F7"
            pt=".5rem"
          >
            <Stack direction="row" gap=".25rem">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <SvgWrapper
                  icon={Mention}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ fill: '#475467' }}
                />
              </Box>
            </Stack>

            <Stack direction="row" gap=".25rem">
              <Box
                padding="0.5rem"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setAddComment(false);
                  setParentCommentId('');
                  setCommentId('');
                  setNewComment('');
                  setIsEdit(false);
                }}
              >
                <SvgWrapper
                  height="1rem"
                  width="1rem"
                  icon={X}
                  styleOverrides={{ fill: '#475467' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={e => {
                  e.preventDefault();
                  editCheck ? handleEditComment() : handleAddComment();
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: '#475467'
                    }}
                  />
                ) : (
                  <SvgWrapper
                    icon={Send}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: '#475467' }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Button
          color="primary"
          transparent
          size="md"
          text="Add new Comment"
          width="inherit"
          icon={Plus}
          iconPosition="start"
          onClick={() => setAddComment(true)}
        />
      )}
    </Box>
  );
};

export default AddComment;
