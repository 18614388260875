import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { shadowsTheme } from 'themes/shadows';

interface CommentMenuProps {
  id?: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  menuOptions: {
    title: string;
    image: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    onClick: () => void;
  }[];
}

const CommentMenu = ({
  id,
  anchorEl,
  open,
  onClose,
  menuOptions
}: CommentMenuProps) => {
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiMenu-paper': {
          borderRadius: '0.5rem',
          border: '.0625rem solid #EAECF0',
          boxShadow: shadowsTheme.xxSmallShadowSoft,
          '& .MuiMenu-list': {
            padding: 0
          }
        }
      }}
      elevation={0}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {menuOptions.map((element, index) => {
        return (
          <MenuItem
            key={index}
            sx={{
              padding: '.5rem .75rem',
              display: 'flex',
              gap: '.5rem',
              borderBottom: '.03rem solid #EAECF0',
              backgroundColor: '#ffffff'
            }}
            onClick={() => {
              element.onClick();
              onClose();
            }}
          >
            <SvgWrapper
              icon={element.image}
              width="0.75rem"
              height="0.75rem"
              styleOverrides={{ fill: '#475467' }}
            />
            <Typography variant="bodyMediumMedium" color="#475467">
              {element.title}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default CommentMenu;
