import React, { useState } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/arrowDown.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as Trash } from 'assets/svg/trash.svg';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as VerticalMenu } from 'assets/svg/vertical-menu.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { WorkflowStepCommentsApiData } from 'services/types/workflow';
import CommentMenu from './commentMenu';
import WorkflowViewerReplyCommentCard from './replyCommentCard';

dayjs.extend(relativeTime);

type WorkflowViewerCommentCardProps = {
  comment: WorkflowStepCommentsApiData;
  stepTitle: string;
  commentNo: number;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  setAddComment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  addReply: () => void;
  handleDeleteComment: () => void;
};

const WorkflowViewerCommentCard = ({
  comment,
  stepTitle,
  commentNo,
  setCommentId,
  setNewComment,
  setAddComment,
  setIsEdit,
  addReply,
  handleDeleteComment
}: WorkflowViewerCommentCardProps) => {
  const [viewReply, setViewReply] = useState<boolean>(false);
  const [viewComment, setViewComment] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const isWithinTenMinutes =
    dayjs().diff(dayjs(comment.updated_at || comment.created_at), 'minute') <=
    10;

  const handleEdit = () => {
    setCommentId(comment.comment_id);
    setNewComment(comment.comment);
    setIsEdit(true);
    setAddComment(true);
  };

  return (
    <>
      <Stack
        direction="column"
        padding="0rem 0.75rem"
        alignItems="center"
        width="inherit"
        sx={{
          border: '.064rem solid #F5F6F7',
          borderTop: 'none',
          background: '#FFF'
        }}
        id={comment.comment_id}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: '0.75rem',
            width: '100%'
          }}
        >
          <Stack direction="column" width="inherit">
            <Stack
              direction="row"
              width="100%"
              gap=".5rem"
              alignItems="center"
              justifyContent="space-between"
            >
              <Avatar
                src={comment.user.profile_picture}
                style={{ width: '2rem', height: '2rem' }}
              />
              <Stack
                direction="row"
                width="auto"
                gap=".5rem"
                alignItems="center"
              >
                <Button
                  color="grey"
                  transparent
                  size="sm"
                  text="Reply"
                  styleOverrides={{
                    border: 'none',
                    background: '#FCFCFC'
                  }}
                  onClick={addReply}
                />
                {isWithinTenMinutes && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      setCommentId(comment.comment_id);
                      setAnchorEl(e.currentTarget);
                    }}
                    aria-describedby={id}
                  >
                    <SvgWrapper
                      icon={VerticalMenu}
                      width="1rem"
                      height="1rem"
                      styleOverrides={{ fill: '#667085' }}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>

            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="column" justifyContent="flex-start">
                <Typography variant="bodyMediumSemibold" color="#475467">
                  {comment.user.full_name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.25rem'
                  }}
                >
                  <Typography variant="captionXSmall" color="#98A2B3">
                    {dayjs(comment.updated_at || comment.created_at).fromNow()}
                  </Typography>
                  <Box
                    component="img"
                    src={ellipse}
                    sx={{
                      color: '#D0D5DD',
                      width: '0.25rem',
                      height: '0.25rem'
                    }}
                  />
                </Box>
              </Stack>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewComment(!viewComment);
                  setViewReply(false);
                }}
              >
                {viewComment ? (
                  <SvgWrapper
                    icon={ChevronDown}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ stroke: '#667085', fill: 'none' }}
                  />
                ) : (
                  <SvgWrapper
                    icon={ChevronUp}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ stroke: '#667085', fill: 'none' }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>

        {(commentNo === 1 ? !viewComment : viewComment) && (
          <Stack direction="column" width="100%" gap=".5rem">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '.25rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                #{commentNo}
              </Typography>
              <Box
                component="img"
                src={ellipse}
                sx={{ color: '#D0D5DD', width: '0.25rem', height: '0.25rem' }}
              />
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                {stepTitle}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: 'inherit',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                {comment.comment}
              </Typography>
            </Box>
          </Stack>
        )}

        {(commentNo === 1 ? !viewComment : viewComment) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              cursor: 'pointer',
              gap: '0.5rem',
              margin: '.75rem'
            }}
            onClick={() => setViewReply(!viewReply)}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              {comment.replies.length}{' '}
              {comment.replies.length === 1 ? 'Reply' : 'Replies'}
            </Typography>
            {viewReply && comment.replies.length >= 1 ? (
              <SvgWrapper
                icon={ChevronUp}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#667085', fill: 'none' }}
              />
            ) : (
              <SvgWrapper
                icon={ChevronDown}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#667085', fill: 'none' }}
              />
            )}
          </Box>
        )}
      </Stack>

      <CommentMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        menuOptions={[
          {
            title: 'Edit',
            image: Edit,
            onClick: handleEdit
          },
          {
            title: 'Delete',
            image: Trash,
            onClick: handleDeleteComment
          }
        ]}
      />

      {viewReply &&
        comment.replies.length >= 1 &&
        comment.replies.map(reply => (
          <WorkflowViewerReplyCommentCard
            key={reply.comment_id}
            addReply={addReply}
            reply={reply}
            setCommentId={setCommentId}
            setNewComment={setNewComment}
            setAddComment={setAddComment}
            setIsEdit={setIsEdit}
            handleDeleteComment={handleDeleteComment}
          />
        ))}
    </>
  );
};

export default WorkflowViewerCommentCard;
