import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Tabs as MUITabs,
  Tab as MUITab
} from '@mui/material';
import AnalyticsPage from './analytics/analyticsPage';
import CreateReportModal from './analytics/createReportModal';
import DateRangePickerComponent from 'components/date-range-picker/dateRangePicker';
import ReportsPage from './reports/reportsPage';
import { Button } from 'components/button';
import _ from 'lodash';

interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
}

const tabList = ['analytics', 'reports'];

const AnalyticsAndReportsPage = () => {
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTabIndex];
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentTabIndex(newTabIndex);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack direction="column" gap={0.5}>
            <Typography variant="h6Bold" color="#475467">
              Analytics & Reports
            </Typography>
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              See data analytics for all key actions
            </Typography>
          </Stack>

          {currentTabIndex === 0 && (
            <Button
              text="Create Report"
              color="primary"
              size="md"
              onClick={() => setCreateReportModalOpen(true)}
            />
          )}
          {currentTabIndex === 1 && (
            <DateRangePickerComponent
              dateRange={dateRange}
              setDateRange={setDateRange}
              btnText="Select Date"
              preferredEndIcon={
                <ChevronDown
                  style={{
                    fill: '#fff',
                    stroke: '#475467',
                    width: '1rem',
                    height: '1rem',
                    marginLeft: '.25rem'
                  }}
                />
              }
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          width: '100%',
          top: '5.3rem',
          left: '0',
          zIndex: 2,
          pl: '1.25rem',
          backgroundColor: 'white',
          position: 'absolute'
        }}
      >
        <MUITabs
          orientation="horizontal"
          value={Math.max(0, currentTabIndex)}
          onChange={(e, value) => handleChange(value)}
          sx={{
            borderBottom: 1,
            borderColor: '#F5F6F7',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {tabList.map(tabId => (
            <MUITab
              key={tabId}
              label={
                <Typography variant="bodyMediumMedium">
                  {_.startCase(tabId.replace('-', ' '))}
                </Typography>
              }
            />
          ))}
        </MUITabs>
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('analytics')}
        sx={{ width: '100%' }}
      >
        <AnalyticsPage />
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('reports')}
        sx={{ width: '100%' }}
      >
        <ReportsPage
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
        />
      </Box>
      <CreateReportModal
        open={createReportModalOpen}
        onClose={() => setCreateReportModalOpen(false)}
        setCreateReportModalOpen={setCreateReportModalOpen}
      />
    </Box>
  );
};

export default AnalyticsAndReportsPage;
