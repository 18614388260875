import React, { useMemo, useState } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';

import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import MerchantDistributors from './drawers/merchantDistributors';
import { Merchant } from '../types';

const emptyMerchantData: Merchant[] = [];

const columnHelper = createColumnHelper<Merchant>();
const columns = [
  columnHelper.accessor('merchant', {
    header: 'merchant',
    cell: info => (
      <Stack display="flex" flexDirection="row">
        <Avatar
          src={`${info.getValue().logo}`}
          sx={{
            width: '2rem',
            height: '2rem',
            mr: '0.75rem',
            justifyContent: 'center',
            borderRadius: '12.5rem'
          }}
        />
        <Stack display="flex" justifyContent="center">
          <Typography
            variant="bodyMediumMedium"
            color="#475467"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {info.getValue().name}
          </Typography>
        </Stack>
      </Stack>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('public_id', {
    header: 'id number',
    cell: info => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('address', {
    header: 'headquarters',
    cell: info => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<Merchant>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const Merchants = () => {
  const { data: merchants, isLoading: isLoadingMerchants } =
    useGetDiscoverableProfilesQuery({
      params: {
        organization_type: 'merchant'
      }
    });

  const formattedMerchants = useMemo(() => {
    if (!merchants) return emptyMerchantData;

    return Object.values(merchants).map(merchant => ({
      merchant: {
        name: merchant.name,
        logo: merchant.logo
      },
      public_id: merchant.organization_id,
      address: merchant.address,
      name: merchant.name,
      logo: merchant.logo,
      registered: 'yes'
    }));
  }, [merchants]);

  const [isOpen, setIsOpen] = useState(false);

  const [selectedMerchant, setSelectedMerchant] = useState<Merchant | null>(
    null
  );

  const handleRowClick = (merchant: Merchant) => {
    setSelectedMerchant(merchant);
    setIsOpen(true);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack gap=".25rem" width="inherit">
            <Typography variant="h6Bold" color="#475467">
              All Merchants
            </Typography>
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              View all merchants registered on Flux
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box mt="3rem">
        {selectedMerchant && (
          <MerchantDistributors
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setSelectedMerchant(null);
            }}
            merchant={selectedMerchant}
          />
        )}
        <Table<Merchant>
          hover
          columns={columns}
          data={formattedMerchants}
          onClickRow={handleRowClick}
          optionsButton
          enableFilter={false}
          filterColumns={['name']}
          searchPlaceholderText="Search for Merchants"
          loadingTable={isLoadingMerchants}
        />
      </Box>
    </Box>
  );
};

export default Merchants;
