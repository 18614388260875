import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DistributorLenders from './drawers/distributorLenders';
import { TabContainer } from 'components/tabs';
import DistributorTable from './tables/distributorTable';
import PickupRequestTable from '../../pickup/pickupRequestTable';

const DistributorsRegisteredToMerchant = () => {
  const [isOpen, setIsOpen] = useState(false);

  const tabs = [
    {
      labelText: 'Distributor list',
      content: <DistributorTable setIsOpen={setIsOpen} />
    },
    {
      labelText: 'Pickup request',
      content: <PickupRequestTable />
    }
  ];

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '2rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.75rem'
          }}
        >
          <Stack gap=".25rem" width="inherit">
            <Typography
              variant="h6Bold"
              color="#475467"
              sx={{ width: '10.75rem' }}
            >
              Distributors
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              color="#98A2B3"
              sx={{ width: '23rem' }}
            >
              See all Distributors and Pickups
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          padding: '1rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '5rem',
          left: '0',
          zIndex: 2,
          position: 'absolute'
        }}
      >
        <DistributorLenders
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />

        <TabContainer tabs={tabs} />
      </Box>
    </Box>
  );
};

export default DistributorsRegisteredToMerchant;
