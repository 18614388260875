import React, { useState, createContext, ReactNode, useEffect } from 'react';
import Loading from 'components/loader/loading';
import { useLazyGetWorkflowQuery } from 'services/api/workflowApi';

import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { strToBool } from 'utilities/helperFunc/strToBool';
import { WorkflowSchemaStepApiData } from 'services/types/workflow';

type WorkflowStep = {
  title: string;
  description: string;
  ownedByRole: string;
  ownedByTeam: string;
  completion_time: string;
  completion_time_time?: string;
  completion_time_interval?: string;
};

const initialValues: WorkflowStep[] = [
  {
    title: 'Step 1',
    description: 'Enter a description',
    ownedByRole: '',
    ownedByTeam: '',
    completion_time: '',
    completion_time_time: '',
    completion_time_interval: ''
  }
];

type ChildProps = {
  children: ReactNode;
};

type WorkflowBuilderContextTypes = {
  workflowSteps: WorkflowStep[];
  setWorkflowSteps: React.Dispatch<React.SetStateAction<WorkflowStep[]>>;
  addNewStep: () => void;
  resetVersionOpen: boolean;
  setResetVersionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentWorkflowVersion: number;
};

const WorkflowBuilderContextDefaults: WorkflowBuilderContextTypes = {
  workflowSteps: [],
  setWorkflowSteps: () => undefined,
  addNewStep: () => undefined,
  resetVersionOpen: false,
  setResetVersionOpen: () => undefined,
  currentWorkflowVersion: 1
};

export const WorkflowBuilderContext =
  createContext<WorkflowBuilderContextTypes>(WorkflowBuilderContextDefaults);

const WorkflowBuilderContextWrapper = ({ children }: ChildProps) => {
  const [searchParams] = useSearchParams();
  const useCase = searchParams.get('workflowUseCase');
  const createMode = strToBool(searchParams.get('create') ?? 'False');

  const [workflowSteps, setWorkflowSteps] =
    useState<WorkflowStep[]>(initialValues);
  const [resetVersionOpen, setResetVersionOpen] = useState<boolean>(false);
  const [currentWorkflowVersion, setCurrentWorkflowVersion] =
    useState<number>(1);
  const [getWorkflow, { isFetching, isLoading }] = useLazyGetWorkflowQuery();

  useEffect(() => {
    (async () => {
      // Calculate values for workflows state
      // Calculate initial workflow steps
      if (useCase) {
        await getWorkflow({
          useCase: useCase,
          params: { include_drafts: 'True' }
        })
          .unwrap()
          .then(savedWorkflow => {
            if (savedWorkflow && _.isNumber(savedWorkflow.version)) {
              setCurrentWorkflowVersion(
                createMode ? savedWorkflow.version + 1 : savedWorkflow.version
              );
              const savedWorkflowSteps: WorkflowStep[] = [];
              Object.values<WorkflowSchemaStepApiData>(
                savedWorkflow.steps
              ).forEach(step => {
                savedWorkflowSteps.push({
                  title: step.step_title,
                  description: step.step_description,
                  ownedByRole: step.owned_by_role
                    ? step.owned_by_role.role_id
                    : '',
                  ownedByTeam: step.owned_by_team
                    ? step.owned_by_team.team_id
                    : '',
                  completion_time: step.completion_time
                    ? step.completion_time
                    : '',
                  completion_time_time: undefined,
                  completion_time_interval: undefined
                });
              });
              setWorkflowSteps(savedWorkflowSteps);
            }
          })
          .catch(error => console.log(error));
      }
    })();
  }, []);

  const addNewStep = () => {
    const newStepIdx = workflowSteps.length + 1;
    const newStep: WorkflowStep = {
      title: `Step ${newStepIdx}`,
      description: 'Enter a description',
      ownedByRole: '',
      ownedByTeam: '',
      completion_time: '',
      completion_time_time: '',
      completion_time_interval: ''
    };
    setWorkflowSteps([...workflowSteps, newStep]);
  };

  const values: WorkflowBuilderContextTypes = {
    workflowSteps,
    setWorkflowSteps,
    addNewStep,
    resetVersionOpen,
    setResetVersionOpen,
    currentWorkflowVersion
  };

  // Return early if data is still loading
  if (isFetching || isLoading) {
    return (
      <Loading
        loading={isFetching || isLoading}
        loadingText="Starting Workflow Builder"
      />
    );
  }
  return (
    <WorkflowBuilderContext.Provider value={values}>
      {children}
    </WorkflowBuilderContext.Provider>
  );
};

export default WorkflowBuilderContextWrapper;
