import React, { useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import { useAppSelector } from 'services/hook';
import Loading from 'components/loader/loading';
import IndicatorCard from './cards/indicatorCard';
import WorkflowCard from './cards/workflowCard';
import { DashboardProductReceiptTable } from './tables';
import { formatLargeAmount } from 'utilities/helperFunc/formatter';
import { useGetUserQuery } from 'services/api/userApi';
import { useGetUserWorkflowQuery } from 'services/api/workflowApi';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { useGetRecentProductReceiptsQuery } from 'services/api/productReceiptApi';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { PermissionType } from 'services/enums/permissions';
import { useGetProductReceiptsSummaryQuery } from 'services/api/analyticsApi';
import IndicatorSkeleton from 'components/skeleton/indicatorCardLoader';
import DateRangePickerComponent from 'components/date-range-picker/dateRangePicker';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { cardText } from 'copy/analytics';

interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
}

const getDefaultStartDate = () => {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 3);
  return startDate;
};

const TODAY = new Date();
const DEFAULT_START_DATE = getDefaultStartDate();
const DEFAULT_END_DATE = new Date(
  TODAY.getFullYear(),
  TODAY.getMonth(),
  TODAY.getDate()
);

const DashboardPage = () => {
  const { data: userData } = useGetUserQuery({});
  const { organizationType } = useAppSelector(state => state.auth);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    key: 'selection'
  });

  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetPermissionsQuery({});

  const hasProductReceiptViewVaultPermission = permissions?.includes(
    PermissionType.PRODUCT_RECEIPT_VIEW_VAULT
  );
  const hasWorkflowViewPermission = permissions?.includes(
    PermissionType.WORKFLOW_VIEW
  );

  const { data: productReceiptData, isLoading } =
    useGetRecentProductReceiptsQuery(
      hasProductReceiptViewVaultPermission
        ? {
            params: {
              count: '5'
            }
          }
        : skipToken
    );

  const { data: activeWorkflowData } = useGetUserWorkflowQuery(
    hasWorkflowViewPermission ? { params: { limit: '5' } } : skipToken
  );

  const { data: productReceiptSummary } = useGetProductReceiptsSummaryQuery(
    hasProductReceiptViewVaultPermission
      ? {
          params: {
            start: formattedDate(
              dateRange.startDate || DEFAULT_START_DATE,
              'YYYY-MM-DD'
            ),
            end: formattedDate(
              dateRange.endDate || DEFAULT_END_DATE,
              'YYYY-MM-DD'
            )
          }
        }
      : skipToken
  );

  const indicatorItems = productReceiptSummary
    ? [
        {
          title: cardText[organizationType]?.[0].title,
          value: formatLargeAmount(
            productReceiptSummary.total_value,
            true,
            true,
            1
          ),
          tooltipText: cardText[organizationType]?.[0].tooltipText
        },
        {
          title: cardText[organizationType]?.[1].title,
          value: formatLargeAmount(
            productReceiptSummary.total_count,
            true,
            false
          ),
          tooltipText: cardText[organizationType]?.[1].tooltipText
        },
        {
          title: cardText[organizationType]?.[2].title,
          value: `${productReceiptSummary.pickup_rate}%`,
          tooltipText: cardText[organizationType]?.[2].tooltipText
        }
      ]
    : null;

  const transformedProductReceiptData = useMemo(() => {
    const emptyProductReceiptData: MiniProductReceiptApiData[] = [];
    if (productReceiptData) {
      return Object.values(productReceiptData);
    }
    return emptyProductReceiptData;
  }, [productReceiptData]);

  if (isLoadingPermissions) {
    return <Loading loading={isLoadingPermissions} />;
  }

  const pendingWorkflowCount = activeWorkflowData
    ? activeWorkflowData.filter(
        workflow => workflow.completed_steps !== workflow.total_steps
      ).length
    : 0;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'center',
          padding: '1.875rem',
          mb: '2.06rem',
          backgroundColor: '#ffffff',
          width: '100%',
          top: '0',
          left: '0',
          position: 'absolute'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.subheading}`,
              fontWeight: 600,
              lineHeight: '1.5rem'
            }}
          >
            Welcome Back, {userData?.firstname} {userData?.lastname}
          </Typography>

          <Typography
            variant="bodyMediumMedium"
            sx={{
              fontWeight: 600,
              lineHeight: '1.5rem',
              color: '#667085'
            }}
          >
            You have {pendingWorkflowCount} pending workflow
            {pendingWorkflowCount === 1 ? '' : 's'}
          </Typography>
        </Box>

        <Box sx={{ marginLeft: 'auto' }}>
          <DateRangePickerComponent
            dateRange={dateRange}
            defaultDateRange={{
              startDate: DEFAULT_START_DATE,
              endDate: DEFAULT_END_DATE,
              key: 'selection'
            }}
            setDateRange={setDateRange}
            btnText="Select date"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.375rem',
          mt: '2.6rem',
          width: 'inherit'
        }}
      >
        {!indicatorItems
          ? [1, 2, 3].map(index => <IndicatorSkeleton key={index} />)
          : indicatorItems.map((item, index) => (
              <IndicatorCard
                key={index}
                title={item.title ?? ''}
                value={item.value}
                tooltip={item.tooltipText}
              />
            ))}
      </Box>

      {hasWorkflowViewPermission &&
        activeWorkflowData &&
        activeWorkflowData.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              marginTop: '2rem',
              width: 'inherit',
              padding: '0.5rem',
              borderRadius: '0.5rem',
              border: '.063rem solid #F5F6F7',
              backgroundColor: '#FFF'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                backgroundColor: '#FCFCFD',
                border: '.063rem solid #F5F6F7',
                borderRadius: '0.5rem',
                padding: '0.75rem'
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                width="100%"
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  flex="1 0 0"
                >
                  <Typography variant="bodyMediumSemibold">
                    Pending Workflows
                  </Typography>
                  <Typography
                    variant="bodyMediumMedium"
                    color="#98A2B3"
                    sx={{ width: 'auto' }}
                  >
                    View updates for all workflows
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" spacing={1}>
                {activeWorkflowData
                  .filter(workflow => workflow.completed_steps === 0)
                  .map(relevantStep => (
                    <WorkflowCard
                      key={`${relevantStep.title}-${relevantStep.target_object_id}`}
                      totalSteps={relevantStep.total_steps}
                      completedSteps={relevantStep.completed_steps}
                      targetObject={relevantStep.target_object}
                      targetObjectId={relevantStep.target_object_id}
                      lastActionedOn={relevantStep.last_actioned_on}
                      title={relevantStep.title}
                    />
                  ))}
              </Stack>
            </Stack>
          </Box>
        )}

      <Box
        sx={{
          marginTop: '2rem',
          width: 'inherit'
        }}
      >
        <DashboardProductReceiptTable
          data={transformedProductReceiptData}
          isLoading={isLoading}
          organizationType={organizationType}
        />
      </Box>
    </Box>
  );
};

export default DashboardPage;
