import React, { useContext } from 'react';
import { Avatar, Box, Typography, Dialog } from '@mui/material';

import { SvgWrapper } from 'components/svg';
import { ReactComponent as XIcon } from 'assets/svg/x.svg';

import { Button } from 'components/button';
import warning from 'assets/png/workflow-builder/warning.png';

import { WorkflowBuilderContext } from './context/builderContext';

const WorkflowVersionResetModal = () => {
  const { resetVersionOpen, setResetVersionOpen, setWorkflowSteps } =
    useContext(WorkflowBuilderContext);

  const handleClose = () => {
    setResetVersionOpen(false);
  };

  return (
    <Dialog
      onClose={() => {
        handleClose();
      }}
      open={resetVersionOpen}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '.125rem solid #F5F6F7',
            backgroundColor: '#FCFCFD',
            padding: '1.25rem'
          }
        }
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            cursor: 'pointer'
          }}
        >
          <Avatar src={warning} sx={{ width: '3rem', height: '3rem' }} />
          <Box
            onClick={() => {
              handleClose();
            }}
            sx={{ cursor: 'pointer' }}
          >
            <SvgWrapper
              icon={XIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{
                stroke: '#475467'
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            marginBottom: '2.25rem'
          }}
        >
          <Typography variant="bodyLargeSemibold" color="#344054">
            Are you sure?
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            This will reset every step in your builder!
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '.063rem solid #F5F6F7',
            marginTop: '1.25rem'
          }}
        >
          <Button
            color="grey"
            size="lg"
            onClick={() => {
              setResetVersionOpen(false);
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            color="primary"
            size="lg"
            onClick={() => {
              setWorkflowSteps([
                {
                  title: 'Step 1',
                  description: 'Enter a description',
                  ownedByRole: '',
                  ownedByTeam: '',
                  completion_time: ''
                }
              ]);
              setResetVersionOpen(false);
            }}
            text="Yes, Reset"
            width="48%"
          />
        </Box>
      </>
    </Dialog>
  );
};

export default WorkflowVersionResetModal;
