import React, { useContext } from 'react';
import { Box } from '@mui/material';

import WorkflowBuilderStep from './workflowBuilderStep';
import { WorkflowBuilderContext } from './context/builderContext';

const WorkflowsBuilderStepsGroup = () => {
  const { workflowSteps } = useContext(WorkflowBuilderContext);
  return (
    <Box
      sx={{
        height: '90vh',
        width: '100%'
      }}
    >
      {workflowSteps.map((step, index) => (
        <WorkflowBuilderStep
          key={index}
          index={index}
          title={step.title}
          description={step.description}
          ownedByRole={step.ownedByRole}
          ownedByTeam={step.ownedByTeam}
          completion_time={step.completion_time}
          completion_time_time={step.completion_time_time || ''}
          completion_time_interval={step.completion_time_interval || ''}
          renderAddButton={index === workflowSteps.length - 1}
        />
      ))}
    </Box>
  );
};

export default WorkflowsBuilderStepsGroup;
