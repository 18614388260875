import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import { SvgWrapper } from 'components/svg';
import React from 'react';
import { useResolveProductReceiptComplaintMutation } from 'services/api/productReceiptApi';

interface ViewProblemModalProps {
  open: boolean;
  onClose: () => void;
  complaint?: { id: string; type: string; description: string } | null;
  productReceiptId: string | null;
  refetchTableData: () => void;
}

const ViewProblemModal: React.FC<ViewProblemModalProps> = ({
  open,
  onClose,
  complaint,
  productReceiptId,
  refetchTableData
}) => {
  const [resolveProductReceiptComplaint, { isLoading }] =
    useResolveProductReceiptComplaintMutation();

  const handleExitDialog = () => {
    onClose();
  };

  const handleResolveComplaint = async () => {
    if (!complaint?.id || !productReceiptId) return;

    await resolveProductReceiptComplaint({
      complaintId: complaint?.id,
      productReceiptId
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Problem Resolved Successfully',
          subText: 'This problem was successfully resolved.',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        refetchTableData();
        onClose();
      })
      .catch(() => {
        ShowInfo({
          message: 'Report Failed',
          subText: 'Product Receipt Report has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0.75rem'
        }
      }}
    >
      <Box sx={{ px: 2, width: '26.25rem' }}>
        <DialogContent
          sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
        >
          <>
            <Stack
              sx={{
                flexDirection: 'row',
                background: '#FFF',
                pb: '1rem',
                py: '1rem'
              }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="column" alignItems="flex-start">
                  <Typography variant="h7Bold" color="#475467">
                    {complaint && complaint.type}
                  </Typography>
                  <Typography variant="bodyMediumRegular" color="#667085">
                    {complaint && complaint.description}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={handleExitDialog}
                >
                  <SvgWrapper
                    icon={CloseIcon}
                    width="1.25rem"
                    height="1.25rem"
                    styleOverrides={{ fill: '#475467', stroke: 'none' }}
                  />
                </Box>
              </Box>
            </Stack>

            <Divider
              sx={{
                color: '#F5F6F7',
                border: '.06rem solid',
                mt: '-.15rem',
                mb: '.5rem'
              }}
            />
          </>

          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '.75rem',
              my: 2
            }}
          >
            <Button
              color="grey"
              transparent
              size="lg"
              text="Cancel"
              styleOverrides={{
                borderRadius: '.5rem',
                border: '.094rem solid #F2F4F7',
                color: '#475467',
                width: '50%'
              }}
              onClick={onClose}
            />

            <Button
              color="primary"
              size="lg"
              text={'Mark as Resolved'}
              styleOverrides={{
                borderRadius: '.5rem',
                width: '50%'
              }}
              loadingIcon={isLoading}
              onClick={handleResolveComplaint}
            />
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default ViewProblemModal;
