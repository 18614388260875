import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useAppSelector } from 'services/hook';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, useServerSidePagination } from 'components/table';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import {
  useGetProductReceiptHistoryQuery,
  useLazyGetProductReceiptHistoryQuery,
  usePrefetch
} from 'services/api/productReceiptApi';
import { OrganizationType } from 'services/enums/organization';
import { Chip } from 'components/chip';
import { ReactComponent as Flag } from 'assets/svg/flag.svg';
import ViewProblemModal from 'pages/history/modal/viewProblemModal';

interface ProductReceiptHistoryTableProps {
  start?: string;
  end?: string;
  holders?: string[];
  merchants?: string[];
  closed?: string;
}

const PRC_HISTORY_PAGE_SIZE = 5;

const ProductReceiptHistoryTable = ({
  start,
  end,
  holders,
  merchants,
  closed
}: ProductReceiptHistoryTableProps) => {
  const { organizationType } = useAppSelector(state => state.auth);

  const [isViewProblemDialogOpen, setIsViewProblemDialogOpen] = useState(false);
  const [currentProductReceiptId, setCurrentProductReceiptId] = useState<
    string | null
  >(null);
  const [selectedComplaint, setSelectedComplaint] = useState<{
    id: string;
    type: string;
    description: string;
  } | null>(null);

  const columnHelper = createColumnHelper<MiniProductReceiptApiData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC CODE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric',
      enablePinning: true,
      size: 100
    }),
    columnHelper.accessor('product', {
      header: 'PRODUCT',
      cell: info => {
        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {info.getValue().name}
          </Typography>
        );
      },
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => {
        const unit = info.row.original.product?.unit;
        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {`${formattedNumber(info.getValue(), false)} ${unit ? `(${unit})` : ''}`}
          </Typography>
        );
      },
      footer: info => info.column.id,
      enableSorting: false
    }),
    columnHelper.accessor('quoted_price', {
      header: 'VALUE',
      cell: info => {
        const quotedPrice = info.getValue();
        const totalQuantity = info.row.original.total_quantity;
        const totalValue = totalQuantity * quotedPrice;

        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {formattedNumber(totalValue, true)}
          </Typography>
        );
      },
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('holders', {
      header: 'ISSUE TYPE',
      cell: info => (
        <Chip
          label={info.getValue().length > 1 ? 'Intermediary' : 'Direct'}
          size="sm"
        />
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<MiniProductReceiptApiData>[];
  // Cast is a workaround due to issues with tanstack table
  // See https://github.com/TanStack/table/issues/4302

  const updateColumnDefForOrgs = (organizationType: OrganizationType) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().organization_name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.DISTRIBUTOR) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().organization_name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    }
  };

  updateColumnDefForOrgs(organizationType);

  const [fetchPRCHistory, { isLoading, isFetching }] =
    useLazyGetProductReceiptHistoryQuery();
  const prefetchPRCHistory = usePrefetch('getProductReceiptHistory');

  const fetchPaginatedPRCHistory = (
    pageNumber: number
  ): Promise<{ results: MiniProductReceiptApiData[]; count: number }> => {
    return fetchPRCHistory(
      {
        params: {
          page: pageNumber,
          pageSize: PRC_HISTORY_PAGE_SIZE,
          start,
          end,
          holders,
          merchants,
          closed
        }
      },
      true
    ) // Make sure to set prefer cache value to true here
      .unwrap()
      .then(result => {
        return { results: result.results, count: result.count };
      })
      .catch(error => {
        console.log(error);
        return { results: [], count: 0 };
      });
  };

  const prefetchPaginatedPRCHistory = (pageNumber: number) => {
    prefetchPRCHistory({
      params: {
        page: pageNumber,
        pageSize: PRC_HISTORY_PAGE_SIZE,
        start,
        end,
        holders,
        merchants,
        closed
      }
    });
  };

  const { data, rowCount, pagination, prefetchPages, getPage } =
    useServerSidePagination({
      pageSize: 5,
      getServerPage: fetchPaginatedPRCHistory,
      prefetchServerPage: prefetchPaginatedPRCHistory
    });

  // Refetch first page when filters change
  useEffect(() => {
    getPage(1);
  }, [start, end, holders, merchants, closed]);

  const { refetch } = useGetProductReceiptHistoryQuery({
    params: {
      page: 1,
      pageSize: PRC_HISTORY_PAGE_SIZE,
      start,
      end,
      holders,
      merchants,
      closed
    }
  });

  const menuItems = [
    {
      image: Flag,
      displayText: 'View Problem',
      onClickMenuItem: (rowData: MiniProductReceiptApiData) => {
        setCurrentProductReceiptId(rowData.product_receipt_id);
        if (rowData.complaints?.length > 0) {
          const { complaint_id, complaint_type, complaint_description } =
            // Assuming one complaint per row
            rowData.complaints[rowData.complaints.length - 1];
          setSelectedComplaint({
            id: complaint_id,
            type: complaint_type,
            description: complaint_description
          });

          setIsViewProblemDialogOpen(true);
        }
      },
      fill: '#F86F66'
    }
  ];

  return (
    <>
      <Table<MiniProductReceiptApiData>
        hover
        columns={columns}
        data={data}
        menuItemProps={menuItems}
        showCaution={data.some(
          item =>
            item.complaints?.length > 0 &&
            item.complaints[item.complaints.length - 1].resolved_on === null
        )}
        enableFilter={false}
        showSearch={false}
        filterColumns={['product']}
        searchPlaceholderText="Search with product receipt code, product etc"
        filterTabs={['product', 'merchant']}
        loadingTable={isLoading || isFetching}
        getRowCanExpand={() => false}
        showMenu
        getSubRows={row =>
          row.related_product_receipts
            ? (row.related_product_receipts as MiniProductReceiptApiData[])
            : []
        }
        serverSidePagination={pagination}
        serverSideRowCount={rowCount}
        prefetchPages={prefetchPages}
        getPage={getPage}
        tableTitleType="product-receipt-history-table"
      />
      <ViewProblemModal
        open={isViewProblemDialogOpen}
        onClose={() => setIsViewProblemDialogOpen(false)}
        complaint={selectedComplaint}
        productReceiptId={currentProductReceiptId}
        refetchTableData={refetch}
      />
    </>
  );
};

export default ProductReceiptHistoryTable;
