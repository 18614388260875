import dayjs from 'dayjs';

export const timeDifference = (timeString: string) => {
  const time = new Date(timeString);
  const now = new Date();
  const timeDiff = now.getTime() - time.getTime();

  const minutes = Math.floor(Math.abs(timeDiff) / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years} years ago`;
  } else if (weeks > 0) {
    return `${weeks} weeks ago`;
  } else if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return 'Just now';
  }
};

export const millisecsToMinsHr = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ${hours % 24}hr`;
  } else if (hours > 0) {
    return `${hours}hr ${minutes % 60}mins`;
  } else if (minutes > 0) {
    return `${minutes}mins ${seconds % 60}s`;
  } else {
    return `${seconds}secs`;
  }
};

export const formatTime = (time: string) => {
  const now = dayjs();
  const givenTime = dayjs(time);

  if (givenTime.isBefore(now)) return 'late';

  const diffHours = givenTime.diff(now, 'hour');
  const diffDays = givenTime.diff(now, 'day');
  const diffWeeks = givenTime.diff(now, 'week');
  const diffMonths = givenTime.diff(now, 'month');

  if (diffHours < 24 && diffHours >= 1) return `${diffHours} hours`;
  if (diffDays < 7 && diffDays >= 1) return `${diffDays} days`;
  if (diffWeeks < 4 && diffWeeks >= 1) return `${diffWeeks} weeks`;
  if (diffMonths >= 1) return `${diffMonths} months`;

  return 'less than an hour';
};
