import React from 'react';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { ReactComponent as Opened } from 'assets/svg/opened.svg';
import { ReactComponent as Filter } from 'assets/svg/filterLines.svg';
import { SvgWrapper } from 'components/svg';
import GeneralNotifications from './generalNotifications';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const NotificationsDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const getStartingPoint = () => {
    const width = window.innerWidth;
    return (width / 12) * 2.3;
  };

  const getDrawerWidth = () => {
    const width = window.innerWidth;
    const drawerWidth = 24.75 * 16;
    const calcDynamicDrawerWidth = (drawerWidth / width) * 100;
    return `${calcDynamicDrawerWidth}`;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'fixed',
          width: `${getDrawerWidth()}%`,
          left: getStartingPoint(),
          boxShadow: 'none'
        }
      }}
      ModalProps={{
        slotProps: {
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0)'
            }
          }
        }
      }}
      transitionDuration={0}
    >
      <Box
        sx={{
          position: 'fixed',
          height: '100%',
          width: 'inherit',
          left: getStartingPoint(),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          transform: open
            ? 'translateX(0px)'
            : `translateX(-${getStartingPoint()}px)`,
          transition: 'transform 0.8s ease-in-out'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          padding="1rem 1.5rem"
          spacing={1}
          justifyContent="space-between"
          sx={{
            animation: open
              ? 'slideUp 0.5s ease-in-out, fadeIn 0.5s ease-in-out'
              : 'none',
            borderBottom: '.063rem solid #F2F4F7',
            border: '0.09375rem solid transparent',
            background: '#07254A'
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#fff">
            Activity Feed
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="1.25rem"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap=".5rem"
            >
              <SvgWrapper
                icon={Opened}
                width="1rem"
                height="1rem"
                styleOverrides={{ fill: '#98A2B3' }}
              />
              <Typography variant="bodyMediumMedium" color="#fff">
                Mark all as read
              </Typography>
            </Stack>

            <Box
              width="1.75rem"
              height="1.75rem"
              style={{
                display: 'flex',
                padding: '.5rem',
                borderRadius: '.5rem',
                background: '#F5F6F7'
              }}
            >
              <SvgWrapper
                icon={Filter}
                width="0.75rem"
                height="0.75rem"
                styleOverrides={{
                  fill: 'none',
                  stroke: '#667085'
                }}
              />
            </Box>
          </Stack>
        </Stack>

        <Stack
          width="100%"
          sx={{
            height: 'inherit',
            animation: open ? 'fadeIn 0.5s ease-in-out' : 'none'
          }}
        >
          <Box
            sx={{
              maxHeight: '100vh',
              overflow: 'auto'
            }}
          >
            <GeneralNotifications />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          left: `${(getStartingPoint() / window.innerWidth) * 100 + Number(getDrawerWidth())}%`,
          width: '100%',
          height: '100%'
        }}
        onClick={onClose}
      ></Box>
    </Drawer>
  );
};

export default NotificationsDrawer;
